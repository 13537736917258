import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

interface Props {
    slogan:string;
    title:string;
    classes:any;
}

const SectionTitle = ( { slogan, title, classes}:Props ) => {
    return (
        <div className={`section-title ${classes ? classes : ''}`}>
          <span className="pre-title" dangerouslySetInnerHTML={{__html: slogan}}></span>

          <h3 className="title" dangerouslySetInnerHTML={{__html: title}}></h3>

              <div style={{marginTop: 5}}>
                <p>Vuoi provare i nostri servizi di cartomanzia senza impegno? Offriamo la possibilità di richiedere un consulto gratuito per aiutarti a conoscere meglio i nostri cartomanti e il loro metodo di lavoro.</p>
              </div>

        </div>
    )
}
export default SectionTitle;