import React, {useEffect, useState} from 'react';
import {FaSpinner} from 'react-icons/fa';
import CartomanteTile from './CartomanteTile';
import {useGetFortuneTellerQuery} from "../../api/allApi";
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import {CartomantiSliderParams} from "./slider_config";
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';

const CartomantiListHomeInner = () => {

  let {data: cartomanti, error, isLoading} = useGetFortuneTellerQuery();


  const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button`;

  const loading = isLoading || !cartomanti;

  const CartomantiLoaded = () => <>
    <div className="row g-5 align-items-center mb--45 mb--30 mt_md--30 mt_sm--30">
      <div className="col-lg-6">
        <div className="section-title text-start">
          <span className="pre-title">I nostri esperti cartomanti</span>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="button-group isotop-filter filters-button-group d-flex justify-content-start justify-content-lg-end">
        </div>
      </div>
    </div>

    <div className="row g-5">

      <Slider
          className={classes}
          {...CartomantiSliderParams}
      >
        {
          cartomanti!!.map((item, num) => <div className="col-12 col-sm-12 col-xl-4 col-md-6" key={num}>
            <CartomanteTile data={item} classes={"mr--10"}/>
          </div>)
        }
      </Slider>

    </div>

    <div className="row text-center mt--60">
      <div className="col-lg-12">
        <div className="load-more-btn animated fadeInUp">
          <Link className="edu-btn" to="/cartomanti">Mostra tutti<i
              className="icon-arrow-right-line-right"></i></Link>
        </div>
      </div>
    </div>
  </>;

  return (<div>
    <ReactPlaceholder type='media' rows={7} ready={!loading}>
    <CartomantiLoaded/>
  </ReactPlaceholder>
  </div>)
}

export default CartomantiListHomeInner;