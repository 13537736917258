import React from 'react';
import { useParams } from 'react-router-dom';

import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import SignData from './Sign.json';

const SignDetail = () => {
    const { name } = useParams();

    const data = SignData.filter(v => v.name==name)[0];


    return (
        <>
            <SEO name={"sign_detail"} description={`segno zodicale ${data.title} ${data.descrizione}` } title={ `segno zodicale ${data.title}` } />
            <Layout>
                <BreadcrumbOne 
                    title={ data.title }
                    rootUrl="/oroscopo/segni-zodiacali"
                    parentUrl="Segni Zodicali"
                    currentUrl={""+data.title}
                />
                <div className="edu-blog-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-1 style-variation3">

                                    <div className="content-blog-top">


                                        <h4 className="title">{ data.title }</h4>

                                        <div className="thumbnail block-alignwide" style={{textAlign:'center'}}>
                                            <img className="radius-small w-25 mb--30 mr--30" src={`${process.env.PUBLIC_URL}/images/sign/${data.image}`} alt="Blog Thumb" />
                                        </div>
                                    </div>

                                    <div className="blog-main-content">

                                        <blockquote className="blockquote-style-1">“ {data.descrizione}”
                                            <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images"/>
                                        </blockquote>

                                        <p>{data.descrizione_long}</p>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default SignDetail;