import React from "react";

export const renderStars = (count: number) => {

  const stars = Array.from({length: count}, (_, index) => index + 1);

  const _default = () => <div className="rating eduvibe-course-rating-stars">
    {stars && Array.from({length: 5}, (_, index) => index + 1).map((_, index) => (
        <i key={index + "star"} className="icon-Star-Dis"></i>
    ))}
  </div>;

  const starsRender = () => <div className="rating eduvibe-course-rating-stars">
    {stars && stars.map((_, index) => (
        <i key={index + "star"} className="icon-Star"></i>
    ))}
  </div>;

  return count > 0 ? starsRender() : _default();


};
