import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/scrolltotop/ScrollToTop';

// Import Css Here
import './assets/scss/style.scss';
import Home from "./pages/home/Home";
import CartomanteDetail from "./pages/cartomante/CartomanteDetail";
import Faq from "./pages/faq/Faq";
import CartomantiPage from "./pages/cartomanti/CartomantiPage";
import TarotPage from "./pages/tarot/TarotPage";
import TarotDetail from "./pages/tarot/TarotDetail";
import SignPage from "./pages/sign/SignPage";
import SignDetail from "./pages/sign/SignDetail";
import Oroscopo from "./pages/oriscopo/Oroscopo";
import Contact from "./pages/contacts/Contact";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import PurchaseGuide from "./pages/purchase-guide/PurchaseGuide";
import CartomanziaGratis from "./pages/free-consult/FreeConsult";
import NotFound from "./pages/not_found/NotFound";
import "react-placeholder/lib/reactPlaceholder.css";
import {useTracker} from "./hooks/tracking/tracking.hook";
import Review from "./pages/Review";
import Buy from "./pages/Buy";

const App = () => {

  const tracker = useTracker();

  useEffect(() => {
    const errorHandler = (error:any) => {
      tracker.trackWithData('checkout', 'generic_error', {url: window.location.href,error})
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return (
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/faq' element={<Faq/>}/>
            <Route path='/contatti' element={<Contact/>}/>
            <Route path='/purchase-guide' element={<PurchaseGuide/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/cartomanti' element={<CartomantiPage/>}/>
            <Route path='/cartomanzia-gratis' element={<CartomanziaGratis/>}/>
            <Route path='/tarocchi/arcani-maggiori' element={<TarotPage/>}/>
            <Route path='/tarocchi/arcani-maggiori/:name' element={<TarotDetail/>}/>
            <Route path='/oroscopo/segni-zodiacali' element={<SignPage/>}/>
            <Route path='/oroscopo/giornaliero' element={<Oroscopo/>}/>
            <Route path='/oroscopo/segno/:name' element={<SignDetail/>}/>
            <Route path='/cartomante/:name' element={<CartomanteDetail/>}/>
            <Route path='/review/:id' element={<Review/>}/>
            <Route path='/buy-service' element={<Buy/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </ScrollToTop>
      </Router>
  );
};

export default App;
