import React from 'react';
import Slider from 'react-slick';
import {TestimonialHomeParams} from '../../utils/script';
import {CartomanteReviewResponse} from "../../api/allApi";


const HomeTestimonialInner = ({props,reviews}:{props?:any, reviews:CartomanteReviewResponse[]} ) => {
    const classes = `eduvibe-testimonial-four edu-testimonial-activation testimonial-item-3 mt--40 edu-slick-button ${props?.className ? props.className : '' }`;
    return (
        <Slider
            className={classes}
            { ...TestimonialHomeParams }
        >
            { reviews.map( ( item, i ) => {
                const html = "“ " + item.review + " “";
                return (
                    <div className="testimonial-card-box" key={i}>
                        <div className="inner">
                            <div className="client-info">
                                <div className="thumbnail">
                                    <img src={item.imageUri} alt={"cartomante " + item.name}/>
                                </div>
                                <div className="content">
                                    <h6 className="title">{item.cartomante}</h6>

                                </div>
                            </div>
                            <p className="description" dangerouslySetInnerHTML={{__html: html}}/>
                            <div className="rating eduvibe-course-rating-stars">
                                {[...Array(5)].map((_, index) => {
                                    return <i key={index} className={index < (item.star || 0) ? 'on icon-Star' : 'off icon-Star'}></i>
                                })}
                            </div>

                            <div className="client-info">
                                <div className="content">
                                    <span className="designation">{item.name}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                );
            })}
        </Slider>
    )
}


export default HomeTestimonialInner;