import React, {useEffect, useRef, useState} from 'react';
import {useRequestFreeConsultMutation, useSaveContactRequestMutation} from "../../api/allApi";
import ReCAPTCHA from "react-google-recaptcha";
import {useCheckCanSendConsult} from "./useCheckCanSendConsult";
import {useTracker} from "../../hooks/tracking/tracking.hook";


const Result = () => {
  return (
      <p className="success-message">Richiesta inviata.</p>
  )
}

const NoRequest = () => {
  return (
      <>
        <p className="success-message">Hai già richiesto un consulto gratuito. Ti preghiamo di attendere la risposta.</p>
        <p className="success-message">Puoi richiedere un solo consulto gratuito.</p>
      </>
  )
}

const FreeConsultForm = ({formStyle}: { formStyle?: any }) => {
  const [result, setResult] = useState(false);

  const tracker = useTracker();

  const recaptcha = useRef<ReCAPTCHA>(null);

  const [requestFreeConsult, { isLoading: isUpdating }] = useRequestFreeConsultMutation();

  const {loading, canSend,user,ip} = useCheckCanSendConsult();

  useEffect(() => {
    if(!canSend && !loading){
      tracker.trackWith('free_consult','free_consult_request_cant_send')
    }

  }, []);


  const [formValue, setFormValue] = useState({fullname1: '', email: '', message: '',date1:'',fullname2:'',date2:''});

  const requestConsult = (e: any) => {
    e.preventDefault();
    tracker.trackWith('free_consult','free_consult_request_send_start')
    const captchaValue = recaptcha.current?.getValue()

    if (!captchaValue) {
      alert('Clicca non sono un robot!')
    } else {
      requestFreeConsult({freeConsultRequest:{session:user,ip:ip||'',captchaValue,...formValue}}).unwrap().then((result) => {
        setResult(true);
        tracker.trackWith('free_consult','free_consult_request_sended')
      }).catch(()=>{
        setResult(false);
        tracker.trackWith('free_consult','free_consult_request_send_fail')
      })
    }


  };

  const onChange = (name: 'fullname1' | 'email' | 'message'|'date1'|'fullname2'|'date2') => {
    return (e: any)=>{
      formValue[name]= e.target.value;
      setFormValue(formValue);
    }
  }

  return (
      <form className={`${formStyle}`} action="" onSubmit={requestConsult}>

        {(!loading && canSend) && <>
          <div className="col-lg-12">
            <div className="form-group">
              <input disabled={result} onChange={onChange('email')} type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required/>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <input disabled={result} onChange={onChange('fullname1')} type="text" className="form-control form-control-lg" name="fullname" placeholder="Il tuo nome*" required/>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <input disabled={result} onChange={onChange('date1')} type="text" onBlur={(e) => {
                e.target.type = 'text'
              }}
                     onFocus={(e) => {
                       e.target.type = 'date'
                     }} className="form-control form-control-lg" name="date1" placeholder="La tua data di nascita*" required/>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <input disabled={result} onChange={onChange('fullname2')} type="text" className="form-control form-control-lg" name="fullname" placeholder="Altro nome*"/>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <input disabled={result} onChange={onChange('date2')} type="text" onBlur={(e) => {
                e.target.type = 'text'
              }}
                     onFocus={(e) => {
                       e.target.type = 'date'
                     }} className="form-control form-control-lg" name="date1" placeholder="Altra Data di nascita*"/>
            </div>
          </div>


          <div className="col-lg-12">
            <div className="form-group">
              <textarea required minLength={5} maxLength={100} disabled={result} onChange={onChange('message')} className="form-control" name="message" placeholder="Il tuo messaggio"></textarea>
            </div>
          </div>


          <div className="text-center" style={{marginBottom: "10px", display: "flex", justifyContent: "center"}}>
            <ReCAPTCHA ref={recaptcha} sitekey={"6Ldi6u4pAAAAAHsz0_wPBzTPU-VOldj6j2CmatNp"}/>
          </div>


          <div style={{marginTop: "10px"}} className="section-title text-start mb--45">
            <p>*Inviando la richiesta a i ccetti nostri termini e condizioni e verrai iscritto alla nostra mail list.</p>
          </div>

          <div className="col-lg-12">
            <button disabled={result || isUpdating} className="rn-btn edu-btn" type="submit" onClick={()=>{
              tracker.trackWith('free_consult','free_consult_request_click_button')
            }}>
              <span>Invia la richiesta</span><i className="icon-arrow-right-line-right"></i>
            </button>
          </div>
        </>}

        {!canSend && !loading && !result ? <div className="form-group"><NoRequest/></div> : null}

        {result ? <div className="form-group"><Result/></div> : null}

      </form>
  )
};

export default FreeConsultForm;