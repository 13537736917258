import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import FreeConsultRequest from "../../common/freeconsult/FreeConsultRequest";
import HeaderOne from "../../common/header/HeaderOne";
import Banner from "../../components/banner/Banner";
import CartomantiListHome from "../../components/home-one/CartomantiListHome";
import AboutCartomanzia from "../../components/home-one/AboutCartomanzia";
import FreeConsultFeature from "../../common/freeconsult/FreeConsultFeature";

const CartomanziaGratis = () => {

  const faq = [
    {
      id: 1,
      title: 'Cos\'è la cartomanzia?',
      body: '' +
          '<p>La cartomanzia è una pratica divinatoria che utilizza le carte da gioco per ottenere intuizioni e previsioni sul futuro. Questa antica arte, che ha radici profonde nelle tradizioni' +
          'esoteriche e spirituali, consente ai cartomanti di interpretare i simboli e le immagini presenti sulle carte per fornire consigli e risposte a domande su vari aspetti della vita,' +
          ' come amore, lavoro, salute e denaro.' +
          '</p>' +
          '<p>Le carte più comunemente usate nella cartomanzia sono i Tarocchi, le Sibille e le carte Lenormand, ognuna delle quali ha un proprio set di simboli e significati. Durante un consulto' +
          ' di cartomanzia, il cartomante dispone le carte in specifici schemi, noti come "stese", e interpreta le combinazioni e le posizioni delle carte per rivelare informazioni nascoste e guidare il consultante.' +
          '</p>' +
          '<p>La cartomanzia è apprezzata non solo per le sue potenzialità predittive, ma anche per la sua capacità di offrire introspezioni personali e riflessioni profonde. Molti trovano' +
          ' conforto e chiarezza attraverso i consulti di cartomanzia, utilizzandoli come uno strumento di crescita personale e spirituale.' +
          '</p>' +
          'Se desideri esplorare il mondo della cartomanzia e scoprire cosa le carte hanno da dirti, i nostri esperti cartomanti sono a tua disposizione per offrirti consulti personalizzati e professionali. Contattaci per prenotare una lettura e iniziare il tuo viaggio nella conoscenza di te stesso e del tuo futuro.'
    },
    {
      id: 2,
      title: 'La cartomanzia può predire il futuro con precisione?',
      body: '<p>La cartomanzia non è una scienza esatta e non può predire il futuro con certezza assoluta. Tuttavia, può offrire intuizioni e indicazioni preziose che aiutano a comprendere meglio situazioni presenti e future. Molte persone trovano che i consulti di cartomanzia forniscono prospettive utili e li aiutano a prendere decisioni più consapevoli.</p>' +
          '<p>Non è necessario avere una forte convinzione nella cartomanzia per ottenere un consulto utile. Anche i più scettici possono trarre beneficio dalle intuizioni offerte durante una lettura. La cartomanzia può essere vista come uno strumento di riflessione personale e di esplorazione interiore, indipendentemente dal livello di credenza del cliente.</p>'
    },
    {
      id: 3,
      title: 'Come posso chiedere un consulto di cartomanzia gratuito?',
      body: '<p>Per richiedere un consulto di cartomanzia gratuito sul nostro sito, segui questi semplici passaggi:</p>' +

          '<p><b>Inserisci email nel form in alto</b>: Inserisci un\'email valida ti chiediamo si seguire le nostre <b></b><a href="/faq">politiche per i consulti gratuti</a></b>.</p>' +

          '<p><b>Partecipazione al sorteggio settimanale </b>: Una volta registrato, sarai automaticamente inserito nella nostra estrazione settimanale. Ogni settimana, selezioniamo casualmente' +
          ' alcuni' +
          ' fortunati utenti per ricevere un consulto di cartomanzia gratuito.</p>' +

          '<p><b> Notifica via email</b>: Se sei stato estratto, riceverai una notifica via email con un link speciale.</p>' +

          '<p><b>Richiedi il consulto</b>: Clicca sul link ricevuto per richiedere il tuo consulto gratuito. Verrai indirizzato a un modulo dove potrai inserire la tua domanda e i dettagli' +
          ' necessari per' +
          ' la' +
          ' lettura. </p>'
    },
    {
      id: 4,
      title: 'I consulti gratuti sono confidenziali?',
      body: 'Assolutamente SI,tutte le domande sono strettamente confidenziali. Riceverai la risposta solo tramite email che hai indicato. Rispettiamo la privacy e la riservatezza di tutti i nostri' +
          ' clienti.' +
          ' Nessuna informazione condivisa verrà divulgata o pubblicata sul sito.'
    }
  ]

  return (
      <>
        <SEO name={"cartomanzia_gratis"} title="Consulto di Cartomanzia Gratis - Richiedi ora!" description={"Richiede un consulto di cartomanzia gratuito. Scopri i misteri del" +
            " destino e dell’amore con le nostre esperte cartomanti!. Cartomanzia via email gratuita e a basso costo"}/>
        <Layout>
          <BreadcrumbOne
              title="Cartomanzia Gratis"
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Cartomanzia Gratis"
          />
          <FreeConsultRequest/>
          <AboutCartomanzia faq={faq}/>

        </Layout>
      </>
  )
}
export default CartomanziaGratis;
