import {getIpInfo} from "./api";
import {useCallback} from "react";
import {useTrackMutation} from "../../api/allApi";
import {pushDataLayerEvent} from "../../utils/utils";


export const useTracker = () => {

    const [track] = useTrackMutation();


    const trackWith = useCallback(
        async (area: string, event: string, data?: string) => {

            pushDataLayerEvent({event, area, data});

            try {
                const resp = await getIpInfo();

                track({
                    audit:{
                        device: navigator.userAgent,
                        ip: resp.ip,
                        event: event,
                        area: area,
                        data: data
                    }

                })
            } catch (e) {
                console.log(e)
            }
        },
        [track],
    );
    const trackWithData = useCallback(
        async (area: string, event: string, data?: any) => {

            pushDataLayerEvent({event, area, data});
            try {
                const resp = await getIpInfo();
                track({
                    audit:{
                        device: navigator.userAgent,
                        ip: resp.ip,
                        event: event,
                        area: area,
                        data: JSON.stringify(data)
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        [track],
    );

    return {
        trackWith,
        trackWithData
    };
}
