
const FeatureItems = [
    {
        title: 'Consulto Breve',
        info: 'Ogni consulto gratuito può contenere massimo una domanda e massimo 50 parole .',
        image: 'feature-image-01.png',
        bgColor: 'card-bg-1',
        link: '#'
    },
    {
        title: 'Una Volta al Mese',
        info: 'Puoi richiedere un consulto gratuito una sola volta al mese per consentire a tutti di usufruire di questa opportunità.',
        image: 'feature-image-02.png',
        bgColor: 'card-bg-2',
        link: '#'
    },
    {
        title: 'Argomenti Specifici',
        info: 'Se vuoi una risposta immediata e garantita con un cartomante a tua scelta, usa i nostri servizi a pagamento.',
        image: 'feature-image-03.png',
        bgColor: 'card-bg-3',
        link: '#'
    }
];

const FreeConsultFeature = ({ wrapperClass, alignment, style }:{wrapperClass?:any, alignment?:any, style?:any} ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--20' }` }>
            { FeatureItems.map( ( data , i ) => (
                <div
                    className={ `col-lg-4 col-md-4 col-12 ${ alignment ? alignment : '' } ${ style ? style : '' }` }
                    key={ i }
                >
                    <div className={ `service-card service-card-2 ${ data.bgColor }` }>
                        <div className="inner">
                            <div className="icon">
                                <a href={ data.link }>
                                    <img src={`${process.env.PUBLIC_URL}/images/feature/feature-01/${ data.image }`}  />
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </div>
    )
}

export default FreeConsultFeature;