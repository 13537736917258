import React from 'react';
import { Link } from 'react-router-dom';
import {useGetFortuneTellerQuery} from "../../api/allApi";
import {firstLetterUpperCase} from "../../app/utils";

const Nav = () => {

  let { data:cartomanti } = useGetFortuneTellerQuery();


    return (
        <ul className="mainmenu">

          <li><Link to="/">Homepage</Link></li>

          <li className="has-droupdown">
            <Link to="/cartomanti">Cartomanti</Link>
            <ul className="submenu">
              {cartomanti && cartomanti.map(ca => {
                return <li key={ca.id}><Link to={`/cartomante/${ca.name}`}>{firstLetterUpperCase(ca.name)}</Link></li>;
              })}

            </ul>
          </li>

          <li>  <Link to="/cartomanzia-gratis">Cartomanzia Gratis</Link></li>

          <li className="has-droupdown">
            <Link to="#">Oroscopo</Link>
            <ul className="submenu">
              <li><Link to="/oroscopo/giornaliero">Giornaliero</Link></li>
              <li><Link to="/oroscopo/segni-zodiacali">Segni zodiacale</Link></li>
              <li><Link to="/tarocchi/arcani-maggiori">I tarocchi</Link></li>
            </ul>
          </li>

          <li className="has-droupdown">
            <Link to="#">Info</Link>
            <ul className="submenu">
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/privacy-policy">Privacy</Link></li>
              <li><Link to="/purchase-guide">Guida acquisto</Link></li>
              {/*<li><Link to="/testimonial">Testimonianze</Link></li>*/}
              <li><Link to="/contatti">Contattaci</Link></li>
            </ul>
          </li>

        </ul>
    )
}
export default Nav;
