import {useNavigate, useParams} from "react-router-dom";
import {useUserTracker} from "../../hooks/useTracker";
import {useGetOrderByIdQuery, useSaveReviewForCartomanteMutation} from "../../api/allApi";
import React, {useEffect, useRef, useState} from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import {capitalize} from "../../utils/utils";
import ReCAPTCHA from "react-google-recaptcha";
import {useTracker} from "../../hooks/tracking/tracking.hook";


const Review = () => {
  const {id} = useParams();
  const recaptcha = useRef<ReCAPTCHA>(null);

  const {data} = useGetOrderByIdQuery({orderId: id!!});

  let cartomante = data?.cartomante;

  const user = useUserTracker();

  const [saveReviewForCartomante] = useSaveReviewForCartomanteMutation();

  const tracker = useTracker();

  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({name: '', review: ''});

  const [star, setStar] = useState(5);

  const onSetStar = (value: number) => {
    setStar(value)
    return false;
  };

  useEffect(() => {
    tracker.trackWith('review', 'page_view', cartomante)
  }, [cartomante]);

  const onChange = (name: 'name' | 'review') => {
    return (e: any) => {
      formValue[name] = e.target.value;
      setFormValue(formValue);
    }
  }


  const save = (e: any) => {
    e.preventDefault();
    const captchaValue = recaptcha.current?.getValue()

    if (!captchaValue) {
      alert('Clicca non sono un robot!')
    } else {
      saveReviewForCartomante({
        name: cartomante!!, reviewRequestSave: {
          name: formValue.name,
          review: formValue.review,
          star: star,
          session: user,
        }
      }).unwrap().then((result) => {
        navigate("/cartomante/" + cartomante);
      }).catch(() => {
      })
    }
  };


  const getClassName = (value: number) => star >= value ? "icon-Star" : "off icon-Star";

  const FormReview = ({formStyle}: { formStyle?: any }) => <div>
    <form className={`${formStyle}`} action="" onSubmit={save}>
      <div className="col-lg-6">
        <div className="form-group">
          <input type="text" onChange={onChange('name')} className="form-control form-control-lg" name="fullname" placeholder="Nome*" required/>
        </div>
      </div>


      <div className="col-lg-12">


        <div className="form-group">

          <div className="rating eduvibe-course-rating-stars">
            <span style={{marginRight: "5px"}}>Voto:</span>
            <i className={getClassName(1)} onClick={() => onSetStar(1)}></i>
            <i className={getClassName(2)} onClick={() => onSetStar(2)}></i>
            <i className={getClassName(3)} onClick={() => onSetStar(3)}></i>
            <i className={getClassName(4)} onClick={() => onSetStar(4)}></i>
            <i className={getClassName(5)} onClick={() => onSetStar(5)}></i>

          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <textarea className="form-control" onChange={onChange('review')} name="message" placeholder="Il tuo messaggio"></textarea>
        </div>
      </div>


      <div className="text-center" style={{marginTop: "10px",marginBottom: "20px", display: "flex", justifyContent: "start"}}>
        <ReCAPTCHA ref={recaptcha} sitekey={"6Ldi6u4pAAAAAHsz0_wPBzTPU-VOldj6j2CmatNp"}/>
      </div>

      <div className="col-lg-12">
        <button className="rn-btn edu-btn" type="submit">
          <span>Invia</span><i className="icon-arrow-right-line-right"></i>
        </button>
      </div>


    </form>
  </div>;

  return (
      <>
        <SEO name={"contact"} title="Recensione" description={"Recensione"} skipIndex={true}/>
        <Layout>
          <BreadcrumbOne
              title="Recensione"
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Recensione"
          />
          {cartomante && <div className="eduvibe-contact-me-top edu-contact-me-area about-me-1 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
              <div className="row g-5">
                <div className="section-title text-start mb--45">
                  <h3 className="title">{"Lascia una recensione per " + capitalize(cartomante)}</h3>
                  <p>Ti ringraziamo di aver usufruito di un consulto con noi saremo molto contenti se lascia una recensione</p>
                </div>
                <FormReview formStyle="rnt-contact-form rwt-dynamic-form row"/>
              </div>

            </div>
          </div>}

        </Layout>
      </>
  )

}

export default Review;