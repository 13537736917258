import React, {useEffect} from 'react'
import {useUserTracker} from "../hooks/useTracker";
import {useTracker} from "../hooks/tracking/tracking.hook";


const SEO = ({name,title, description, skipIndex}: {name:string, title: string, description: string, skipIndex?:boolean}) => {

  useUserTracker();

  const tracker = useTracker();

  useEffect(() => {
    tracker.trackWith(name, 'page_view', name)
  }, []);


  return (
      <>
        <meta charSet="utf-8"/>
        <title> {title}</title>
        {skipIndex &&  <meta name="robots" content="noindex, nofollow"/>}
        <meta name="description" content={description}/>
        <meta name="keywords" content="cartomanti online, cartomanzia via email, consulto di cartomanzia gratis, cartomanzia gratuita, cartomanti esperte, consulti amore, destino, offerte speciali, sconti esclusivi, consulti personalizzati, tarocchi, consulti cartomanzia gratuiti, domande frequenti cartomanzia"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      </>
  )
}

export default SEO;

