import React from 'react';
import CartomantiListHomeInner from '../cartomanti/CartomantiListHomeInner';
import ReactPlaceholder from 'react-placeholder';
import {Element} from "react-scroll";
const CartomantiListHome = () => {
    return (
        <Element name={"cartomanti-list"}>
            <div className="edu-course-area eduvibe-home-two-course course-three-wrapper edu-section-gap bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <CartomantiListHomeInner />
                </div>
            </div>

        </Element>
    )
}

export default CartomantiListHome;