import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import CartomanteBox from "./CartomanteBox";
import {useGetFortuneTellerQuery} from "../../api/allApi";
import {FaSpinner} from "react-icons/fa";

const CartomantiPage = () => {

  let {data: cartomanti, error, isLoading} = useGetFortuneTellerQuery();


  if (isLoading || !cartomanti)
    return <FaSpinner/>;


  return (
      <>
        <SEO name={"cartomanti"} title="Conosci i Nostri Cartomanti - Esperti nel Lettura delle Carte" description={"Scopri i nostri esperti cartomanti specializzati nella lettura delle carte dei" +
            " tarocchi. Ogni" +
            " cartomante è altamente qualificato e pronto ad offrirti consulti personalizzati e accurati."}/>
        <Layout>
          <BreadcrumbOne
              title="Cartomanti"
              rootUrl="/"
              parentUrl="home"
              currentUrl="cartomanti"
          />
          <div className="edu-elements-area edu-section-gap bg-color-white">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <span className="pre-title">Conosci i Nostri Cartomanti - Esperti nel Lettura delle Carte</span>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  cartomanti.map((item, id) => (
                      <div className="col-lg-3 col-md-6 col-sm-6 col-6 mt--45" key={"a"+id}>
                        <CartomanteBox data={item} key={"CartomanteBox"+id}/>
                      </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Layout>
      </>
  )
}

export default CartomantiPage;