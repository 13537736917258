import {IpInfoResponse} from "./types";

export function getIpInfo():Promise<IpInfoResponse>  {

    return fetch(`https://ipinfo.io/json`, {
        signal: AbortSignal.timeout(1000),
    })
    .then(response => {
        if (!response.ok) {
            return Promise.resolve({ip: '0.0.0.0'} as IpInfoResponse);
        }
        return response.json() as Promise<IpInfoResponse>
    }).catch(val => {
        return Promise.resolve({ip: '0.0.0.0'} as IpInfoResponse);
    });
}



