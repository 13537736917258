import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FortuneTellerModel} from "../../api/allApi";

const CartomanteBox = ({ data }:{data:FortuneTellerModel}) => {

  const navigate = useNavigate();

  return (
      <div className="edu-instructor-grid edu-instructor-1" key={data.id}>
        <div className="edu-instructor" onClick={()=>{
          console.log('send')
          navigate(`/cartomante/${data.name}`);
        }}>
          <div className="inner">
            <div className="thumbnail" key={data.id}>
              <a href={"#"}>
                <img src={data.imageUri} alt={data.name} />
              </a>
            </div>
          </div>
        </div>
        <div className="edu-instructor-info">
          <h5 className="title">
            <Link to={process.env.PUBLIC_URL + `/cartomante/${data.name}`}>{data.name}</Link>
          </h5>
          {data.tags?.map(el => {
            return <span className="desc" style={{marginLeft:"2px"}}>
              {el}
            </span>
          })}

        </div>
      </div>
  )
}
export default CartomanteBox;
