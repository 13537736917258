import React, {useState} from 'react';

import {useParams} from 'react-router-dom';

import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

import {FortuneTellerDetailModel, ServiceModel, useCreateOrderMutation, useGetFortuneTellerDetailQuery} from "../../api/allApi";
import {Icon} from "@iconify/react";
import {Tooltip} from "react-tooltip";
import {firstLetterUpperCase, formattedPrice} from "../../app/utils";
import {useUserTracker} from "../../hooks/useTracker";
import {renderStars} from "./Utils";
import ReactPlaceholder from "react-placeholder/lib/ReactPlaceholder";


const tagsMap: { [key: string]: string } = {
  "cartomante": "fluent-emoji-flat:flower-playing-cards",
  "astrologa": "noto:crystal-ball",
  "lifecoach": "game-icons:life-in-the-balance",
};

interface LoadedCartomantiPageProps {
  data: FortuneTellerDetailModel
}

const CartomanteDetail = () => {
  const {name} = useParams();

  const user = useUserTracker();

  const {data} = useGetFortuneTellerDetailQuery({name: name!!});

  const [createOrder] = useCreateOrderMutation();


  const onClickCreateOrder = (service: ServiceModel, name: string) => {
    const promise = createOrder({
      orderRequest: {
        cartomante: name,
        serviceId: service.id!!,
        userReference: user
      }
    }).unwrap();
    promise.then(val => {
      console.log(val);
      window.location.href = val.location!! + '?backUrl=https://www.lamiacartomante.com/cartomante/' + name;
    })
  };

  const [contentTab, setContentTab] = useState('services');
  const handleTab = (content: string) => {
    if (content === 'story') {
      setContentTab('story');
    }
    if (content === 'services') {
      setContentTab('services');
    } else if (content === 'reviews') {
      setContentTab('reviews');
    }
  }

  const active = (service: any) => service.active ? 'pricing-table' : 'pricing-table-disabled';

  const promo = (service: any) => service.discount ? 'active' : '';

  const signToIcons = (sign: string | undefined) => {
    switch (sign) {
      case "ariete":
        return "emojione:aries";
      case "toro":
        return "game-icons:charging-bull";
      case "gemelli":
        return "game-icons:twin-shell";
      case "cancro":
        return "emojione:cancer";
      case "leone":
        return "emojione:lion-face";
      case "vergine":
        return "arcticons:my-virgin-media";
      case "bilancia":
        return "emojione:balance-scale";
      case "scorpione":
        return "noto:scorpion";
      case "sagittario":
        return "emojione-v1:sagittarius";
      case "capricorno":
        return "fluent-emoji-flat:capricorn";
      case "acquario":
        return "la:acquisitions-incorporated";
      case "pesci":
        return "fxemoji:fish";
    }
    return "";
  };

  function reviewCountOf(number: number) {
    return data?.reviewModels?.filter(val => val.star === number).length;
  }

  function reviewCountPercentOf(number: number) {

    const numberOfModels = data?.reviewModels?.length || 0;
    const numberOfStars = data?.reviewModels?.filter(val => val.star === number).length || 0;

    return numberOfModels ? (numberOfStars / numberOfModels) * 100 : 0;
  }


  const renderTags = (tags?: string[]) => {
    return tags?.map((val, key) => {

      return <li key={key + "tag"}><Icon data-tooltip-id={"icon-tag-" + key} data-tooltip-content={val} height={32} width={32}
                                         icon={tagsMap[val]}/>
        <Tooltip id={"icon-tag-" + key} place="top"/></li>
    });
  };

  const LoadedCartomantiPage = ({data}: LoadedCartomantiPageProps) => {


    return <div className="edu-instructor-profile-area edu-section-gap bg-color-white course-details-content">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="instructor-profile-left">
              <div className="inner">
                <div className="thumbnail">
                  <img src={data.imageUri} alt={data.name}/>
                </div>
                <div className="content">
                  <h5 className="title">{firstLetterUpperCase(name)}</h5>

                  {
                    data.characteristics?.map(el => <span className="subtitle" dangerouslySetInnerHTML={{__html:el}}></span>)
                  }

                  <ul className="social-share bg-transparent justify-content-center medium-size">
                    <li>
                      <Icon data-tooltip-id="sign-tooltip" data-tooltip-content={data.sign} height={32} width={32}
                            icon={signToIcons(data.sign)}/>
                      <Tooltip id="sign-tooltip" place="top"/>
                    </li>
                    {renderTags(data.tags)}

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <h3 className="title">Ciao, sono {firstLetterUpperCase(data.name)}</h3>
            <div className="course-details-content">

              <ul className="edu-course-tab nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <button
                      className={contentTab === 'services' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Storia"
                      onClick={() => handleTab('services')}
                  >
                    Consulti
                  </button>
                </li>
                <li className="nav-item">
                  <button
                      className={contentTab === 'story' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="Storia"
                      onClick={() => handleTab('story')}
                  >
                    La mia storia
                  </button>
                </li>
                <li className="nav-item">
                  <button
                      className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                      type="button"
                      aria-label="reviews"
                      onClick={() => handleTab('reviews')}
                  >
                    Recensioni
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              {contentTab === 'services' &&
                  <div className={`tab-pane fade show ${contentTab === 'services' ? 'active' : ''} `}>
                    <div className="instructor-profile-right">
                      <div className="inner">
                        <div className="edu-pricing-area edu-section-gap bg-image">
                          <div className="container">
                            <div className="row g-5">
                              <div className="col-lg-6">
                                <div className="section-title text-start">
                                  <span className="pre-title">Scegli tra uno dei servizi offerti</span>
                                </div>
                              </div>
                              <div className="row g-5 mt--20">
                                {
                                  data.serviceModels?.map((service, index) => (
                                      <div className="col-lg-6 col-md-6 col-12" key={index + "service"}>
                                        <div className={active(service) + ' ' + promo(service)}>
                                          <div className="pricing-header">
                                            <h3 className="title">{service.name}</h3>

                                            {/*{*/}
                                            {/*    service.description &&*/}
                                            {/*    <div*/}
                                            {/*        className={`${service.active ? 'edu-badge' : 'edu-badge-not-avaiable'}`}>*/}
                                            {/*        <span>{service.description}</span></div>*/}
                                            {/*}*/}


                                            <div className="price-wrap">
                                              <div className="yearly-pricing">
                                                <span className="amount">{formattedPrice(service.price)}</span>
                                                {service.price !== service.oldPrice &&
                                                    <span className="duration"
                                                          style={{textDecoration: 'line-through'}}>{formattedPrice(service.oldPrice)}</span>}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="pricing-body">
                                            <ul className="list-item">
                                              <li key={"1a"}><i
                                                  className="icon-checkbox-circle-line"></i>{`Massimo ${service.maxParole} parole`}
                                              </li>
                                              <li key={"1b"}><i
                                                  className="icon-checkbox-circle-line"></i>{`Risposta entro ${service.responseIn} ore`}
                                              </li>
                                              {service.instructions?.map((el, key) =>
                                                  <li key={key + "i"}><i className="icon-checkbox-circle-line">{el}</i>
                                                  </li>)}

                                              <li key={"3b"}><i> <Icon height={20} width={20}
                                                                       icon={"ph:info-duotone"}/></i>{"Maggiori informazioni"}
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="pricing-btn">
                                            <button
                                                onClick={() => {
                                                  onClickCreateOrder(service, data.name!!)
                                                }}
                                                className={` rn-btn edu-btn ${!service.active ? ' btn-dark disabled' : ''}`}
                                                type="submit" disabled={!service.active}>
                                              <span className={"disabled"}>{service.active ? 'Compra adesso' : 'Indisponibile'}</span><i
                                                className="icon-arrow-right-line-right"></i>
                                            </button>
                                          </div>

                                        </div>
                                      </div>))
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              {contentTab === 'story' &&
                  <div className={`tab-pane fade show ${contentTab === 'story' ? 'active' : ''} `}>
                    <div className="instructor-profile-right">
                      <div className="inner">
                        <div className="section-title text-start">
                          <p className="description mt--40" dangerouslySetInnerHTML={{__html:data.story||''}}></p>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              {contentTab === 'reviews' &&
                  <div className={`tab-pane fade show ${contentTab === 'reviews' ? 'active' : ''} `}>
                    <div className={`tab-pane fade show ${contentTab === 'reviews' ? 'active' : ''} `}>
                      <div className="course-tab-content">
                        <div className="row row--30">
                          <div className="col-lg-4">
                            <div className="rating-box">
                              <div className="rating-number">{data.reviewAvg}</div>
                              <div className="rating eduvibe-course-rating-stars">
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                                <i className="icon-Star"></i>
                              </div>
                              <span>({data.reviewCount} Review)</span>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="review-wrapper">

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  5 <i className="icon-Star"></i>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width: reviewCountPercentOf(5) + '%'}}
                                       aria-valuenow={reviewCountPercentOf(5)} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="rating-value">{reviewCountOf(5)}</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  4 <i className="icon-Star"></i>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width: reviewCountPercentOf(4) + '%'}}
                                       aria-valuenow={reviewCountPercentOf(4)} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="rating-value">{reviewCountOf(4)}</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  3 <i className="icon-Star"></i>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width: reviewCountPercentOf(3) + '%'}}
                                       aria-valuenow={reviewCountPercentOf(3)} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="rating-value">{reviewCountOf(3)}</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  2 <i className="icon-Star"></i>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width: reviewCountPercentOf(2) + '%'}}
                                       aria-valuenow={reviewCountPercentOf(2)} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="rating-value">{reviewCountOf(2)}</span>
                              </div>

                              <div className="single-progress-bar">
                                <div className="rating-text">
                                  1 <i className="icon-Star"></i>
                                </div>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width: reviewCountPercentOf(1) + '%'}}
                                       aria-valuenow={reviewCountPercentOf(1)} aria-valuemin={0} aria-valuemax={100}></div>
                                </div>
                                <span className="rating-value">{reviewCountOf(1)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="comment-wrapper pt--40">
                          <div className="section-title">
                            <h5 className="mb--25">Recensioni</h5>
                          </div>
                            {
                              data?.reviewModels?.slice(0,5)?.map(val =>
                                  <div className="edu-comment">
                                    <div className="comment-content">
                                      <div className="comment-top">
                                        <h6 className="title">{val.name}</h6>
                                        {renderStars(val.star || 0)}
                                      </div>
                                      <p dangerouslySetInnerHTML={{__html: val.review || ''}}></p>
                                    </div>
                                  </div>
                              )
                            }
                        </div>
                      </div>
                    </div>
                  </div>
              }

            </div>
          </div>
          {/*<div className={"col-12"}>*/}
          {/*    <div className="instructor-profile-right">*/}
          {/*        <div className="inner">*/}
          {/*            <div className="edu-pricing-area edu-section-gap bg-image">*/}
          {/*                <div className="container">*/}
          {/*                    <div className="row g-5">*/}
          {/*                        <div className="col-lg-6">*/}
          {/*                            <div className="section-title text-start">*/}
          {/*                                <span className="pre-title">Scegli tra uno dei servizi offerti</span>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                        <div className="row g-5 mt--20">*/}
          {/*                            {*/}
          {/*                                data.serviceModels?.map((service, index) => (*/}
          {/*                                    <div className="col-lg-4 col-md-6 col-12" key={index + "service"}>*/}
          {/*                                        <div className={active(service) + ' ' + promo(service)}>*/}
          {/*                                            <div className="pricing-header">*/}
          {/*                                                <h3 className="title">{service.name}</h3>*/}

          {/*                                                /!*{*!/*/}
          {/*                                                /!*    service.description &&*!/*/}
          {/*                                                /!*    <div*!/*/}
          {/*                                                /!*        className={`${service.active ? 'edu-badge' : 'edu-badge-not-avaiable'}`}>*!/*/}
          {/*                                                /!*        <span>{service.description}</span></div>*!/*/}
          {/*                                                /!*}*!/*/}


          {/*                                                <div className="price-wrap">*/}
          {/*                                                    <div className="yearly-pricing">*/}
          {/*                                                        <span className="amount">{formattedPrice(service.price)}</span>*/}
          {/*                                                        {service.price !== service.oldPrice &&*/}
          {/*                                                            <span className="duration"*/}
          {/*                                                                  style={{textDecoration: 'line-through'}}>{formattedPrice(service.oldPrice)}</span>}*/}
          {/*                                                    </div>*/}
          {/*                                                </div>*/}
          {/*                                            </div>*/}
          {/*                                            <div className="pricing-body">*/}
          {/*                                                <ul className="list-item">*/}
          {/*                                                    <li key={"1a"}><i*/}
          {/*                                                        className="icon-checkbox-circle-line"></i>{`Massimo ${service.maxParole} parole`}*/}
          {/*                                                    </li>*/}
          {/*                                                    <li key={"1b"}><i*/}
          {/*                                                        className="icon-checkbox-circle-line"></i>{`Risposta entro ${service.responseIn} ore`}*/}
          {/*                                                    </li>*/}
          {/*                                                    {service.instructions?.map((el, key) =>*/}
          {/*                                                        <li key={key + "i"}><i*/}
          {/*                                                            className="icon-checkbox-circle-line"></i>{el}*/}
          {/*                                                        </li>)}*/}

          {/*                                                    <li key={"3b"}><i> <Icon height={20} width={20}*/}
          {/*                                                                             icon={"ph:info-duotone"}/></i>{"Maggiori informazioni"}*/}
          {/*                                                    </li>*/}
          {/*                                                </ul>*/}
          {/*                                            </div>*/}
          {/*                                            <div className="pricing-btn">*/}
          {/*                                                <button*/}
          {/*                                                    onClick={() => {*/}
          {/*                                                        onClickCreateOrder(service, data.name!!)*/}
          {/*                                                    }}*/}
          {/*                                                    className={` rn-btn edu-btn ${!service.active ? ' btn-dark disabled' : ''}`}*/}
          {/*                                                    type="submit" disabled={!service.active}>*/}
          {/*                                                    <span className={"disabled"}>{"Compra adesso"}</span><i*/}
          {/*                                                    className="icon-arrow-right-line-right"></i>*/}
          {/*                                                </button>*/}
          {/*                                            </div>*/}

          {/*                                        </div>*/}
          {/*                                    </div>))*/}
          {/*                            }*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*        </div>*/}
          {/*    </div>*/}

          {/*</div>*/}


        </div>
      </div>
    </div>;
  };

  return (
      <>
        <SEO name={"cartomante_detail"} title={`Dettagli del Cartomante - ${name}`}
             description={`Scopri i dettagli e le specializzazioni di ${name}, esperta cartomante specializzata nella lettura dei tarocchi e nei consulti sull'amore, il lavoro e la fortuna.`}/>
        <Layout>
          <BreadcrumbOne
              title={"Cartomante"}
              rootUrl="/"
              parentUrl="Home"
              currentUrl={"Cartomante " + firstLetterUpperCase(name)}
          />
          <ReactPlaceholder type='media' rows={20} ready={!!data}>
              {data && <LoadedCartomantiPage data={data}/>}
          </ReactPlaceholder>

        </Layout>
      </>
  )
}
export default CartomanteDetail;