import React, {useRef, useState} from 'react';
import SectionTitle from "./SectionTitle";
import FreeConsultFeature from "./FreeConsultFeature";
import FreeConsultForm from "./FreeConsultForm";


const FreeConsultRequest = () => {


    return (
      <div className="eduvibe-home-three-newsletter edu-newsletter-area newsletter-style-4 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row">
            <div className="col-lg-12">


              <div className="inner text-center">

                <SectionTitle
                    classes="text-center"
                    slogan="Vuoi esplorare i misteri del destino e dell’amore?"
                    title="Richiedi un Consulto Gratuito di cartomanzia"
                />

                <FreeConsultFeature/>

                <div className="newsletter-form newsletter-form-style-1 mt--60 mt_md--30 mt_sm--30 position-relative">

                  <div className={`section-title`}>
                    <h3 className="title" dangerouslySetInnerHTML={{__html: "Come Richiedere il Tuo Consulto Gratuito"}}></h3>

                    <div style={{marginTop: 5}}>
                      <p>Compila il modulo sottostante per richiedere il tuo consulto gratuito. Riceverai la risposta all'indirizzo email indicato</p>
                    </div>

                  </div>

                  <div className="row g-5">
                    <div className="col-lg-12">
                      <div className="section-title text-start mb--45">
                        <p>Ogni utente può richiedere un consulto gratuito una sola volta al mese.</p>
                        <p>Monitoriamo le richieste di consulto per identificare eventuali abusi. Gli utenti che tentano di aggirare le regole (ad esempio, creando account multipli) verranno esclusi dal servizio gratuito.</p>
                        <p>I consulti gratuiti devono essere utilizzati per domande genuine e non per testare continuamente il servizio senza reale necessità. Invitiamo tutti a rispettare lo spirito della nostra offerta</p>
                      </div>
                      <FreeConsultForm formStyle="rnt-contact-form rwt-dynamic-form row"/>
                    </div>
                  </div>



                </div>


              </div>
            </div>
          </div>


          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-04-03.png" alt="Shape Thumb"/>
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-15-04.png" alt="Shape Thumb"/>
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-13-07.png" alt="Shape Thumb"/>
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-02-05.png" alt="Shape Thumb"/>
            </div>
          </div>
        </div>
      </div>
    );
}

export default FreeConsultRequest;