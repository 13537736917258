import {useEffect, useState} from 'react';
import {useCookies} from "react-cookie";
import {v4 as uuidv4} from 'uuid';

export const useUserTracker = () => {

  const [cookies, setCookie] = useCookies(["user"]);

  const [user, setUser] = useState<string>(cookies.user);

  useEffect(() => {

    if (!cookies.user) {
      setCookie('user', uuidv4());
    }

    setUser(cookies.user);

  },[cookies.user, setCookie]);

  return user;
};