import { api } from "./index";
export const addTagTypes = [
  "tracker-controller",
  "order-api",
  "mail-list-controller",
  "free-consult-controller",
  "fortune-teller-api",
  "contact-controller",
  "review-controller",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      track: build.mutation<TrackApiResponse, TrackApiArg>({
        query: (queryArg) => ({
          url: `/tracker`,
          method: "POST",
          body: queryArg.audit,
        }),
        invalidatesTags: ["tracker-controller"],
      }),
      createOrder: build.mutation<CreateOrderApiResponse, CreateOrderApiArg>({
        query: (queryArg) => ({
          url: `/orders`,
          method: "POST",
          body: queryArg.orderRequest,
        }),
        invalidatesTags: ["order-api"],
      }),
      subscribeMailList: build.mutation<
        SubscribeMailListApiResponse,
        SubscribeMailListApiArg
      >({
        query: (queryArg) => ({
          url: `/mail-list`,
          method: "POST",
          body: queryArg.subscribeRequestDto,
        }),
        invalidatesTags: ["mail-list-controller"],
      }),
      unsubscribeMailList: build.mutation<
        UnsubscribeMailListApiResponse,
        UnsubscribeMailListApiArg
      >({
        query: (queryArg) => ({
          url: `/mail-list`,
          method: "DELETE",
          body: queryArg.request,
        }),
        invalidatesTags: ["mail-list-controller"],
      }),
      requestFreeConsult: build.mutation<
        RequestFreeConsultApiResponse,
        RequestFreeConsultApiArg
      >({
        query: (queryArg) => ({
          url: `/free-consult`,
          method: "POST",
          body: queryArg.freeConsultRequest,
        }),
        invalidatesTags: ["free-consult-controller"],
      }),
      saveReviewForCartomante: build.mutation<
        SaveReviewForCartomanteApiResponse,
        SaveReviewForCartomanteApiArg
      >({
        query: (queryArg) => ({
          url: `/fortune-tellers/${queryArg.name}/review`,
          method: "POST",
          body: queryArg.reviewRequestSave,
        }),
        invalidatesTags: ["fortune-teller-api"],
      }),
      saveContactRequest: build.mutation<
        SaveContactRequestApiResponse,
        SaveContactRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/contacts`,
          method: "POST",
          body: queryArg.contactRequest,
        }),
        invalidatesTags: ["contact-controller"],
      }),
      getReviews: build.query<GetReviewsApiResponse, GetReviewsApiArg>({
        query: () => ({ url: `/reviews` }),
        providesTags: ["review-controller"],
      }),
      getOrderById: build.query<GetOrderByIdApiResponse, GetOrderByIdApiArg>({
        query: (queryArg) => ({ url: `/orders/${queryArg.orderId}` }),
        providesTags: ["order-api"],
      }),
      canRequestV2: build.query<CanRequestV2ApiResponse, CanRequestV2ApiArg>({
        query: (queryArg) => ({
          url: `/free-consult/v2/check`,
          headers: {
            userSession: queryArg.userSession,
            userIp: queryArg.userIp,
          },
        }),
        providesTags: ["free-consult-controller"],
      }),
      canRequest: build.query<CanRequestApiResponse, CanRequestApiArg>({
        query: (queryArg) => ({
          url: `/free-consult/check`,
          params: { session: queryArg.session, ip: queryArg.ip },
        }),
        providesTags: ["free-consult-controller"],
      }),
      getFortuneTeller: build.query<
        GetFortuneTellerApiResponse,
        GetFortuneTellerApiArg
      >({
        query: () => ({ url: `/fortune-tellers` }),
        providesTags: ["fortune-teller-api"],
      }),
      getFortuneTellerDetail: build.query<
        GetFortuneTellerDetailApiResponse,
        GetFortuneTellerDetailApiArg
      >({
        query: (queryArg) => ({ url: `/fortune-tellers/${queryArg.name}` }),
        providesTags: ["fortune-teller-api"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as allApi };
export type TrackApiResponse = unknown;
export type TrackApiArg = {
  audit: Audit;
};
export type CreateOrderApiResponse = /** status 200 OK */ OrderResponse;
export type CreateOrderApiArg = {
  orderRequest: OrderRequest;
};
export type SubscribeMailListApiResponse = unknown;
export type SubscribeMailListApiArg = {
  subscribeRequestDto: SubscribeRequestDto;
};
export type UnsubscribeMailListApiResponse = unknown;
export type UnsubscribeMailListApiArg = {
  request: Request;
};
export type RequestFreeConsultApiResponse = unknown;
export type RequestFreeConsultApiArg = {
  freeConsultRequest: FreeConsultRequest;
};
export type SaveReviewForCartomanteApiResponse = unknown;
export type SaveReviewForCartomanteApiArg = {
  name: string;
  reviewRequestSave: ReviewRequestSave;
};
export type SaveContactRequestApiResponse = unknown;
export type SaveContactRequestApiArg = {
  contactRequest: ContactRequest;
};
export type GetReviewsApiResponse =
  /** status 200 OK */ CartomanteReviewResponse[];
export type GetReviewsApiArg = void;
export type GetOrderByIdApiResponse = /** status 200 OK */ OrderById;
export type GetOrderByIdApiArg = {
  orderId: string;
};
export type CanRequestV2ApiResponse = /** status 200 OK */ CanRequestResponse;
export type CanRequestV2ApiArg = {
  userSession: string;
  userIp: string;
};
export type CanRequestApiResponse = /** status 200 OK */ CanRequestResponse;
export type CanRequestApiArg = {
  session?: string;
  ip?: string;
};
export type GetFortuneTellerApiResponse =
  /** status 200 OK */ FortuneTellerModel[];
export type GetFortuneTellerApiArg = void;
export type GetFortuneTellerDetailApiResponse =
  /** status 200 OK */ FortuneTellerDetailModel;
export type GetFortuneTellerDetailApiArg = {
  name: string;
};
export type Audit = {
  device?: string;
  area?: string;
  event?: string;
  data?: string;
  session?: string;
  ip?: string;
};
export type OrderResponse = {
  id?: string;
  location?: string;
};
export type OrderRequest = {
  serviceId: number;
  cartomante: string;
  userReference: string;
};
export type SubscribeRequestDto = {
  captchaValue: string;
  email: string;
};
export type Request = {
  email?: string;
};
export type FreeConsultRequest = {
  email: string;
  fullname1: string;
  date1: string;
  fullname2?: string;
  date2?: string;
  message: string;
  captchaValue: string;
  session: string;
  ip: string;
};
export type ReviewRequestSave = {
  name?: string;
  review?: string;
  star?: number;
  session?: string;
};
export type ContactRequest = {
  email: string;
  fullname: string;
  subject: string;
  message: string;
  captchaValue: string;
};
export type CartomanteReviewResponse = {
  imageUri?: string;
  name?: string;
  date?: string;
  cartomante?: string;
  star?: number;
  review?: string;
};
export type OrderById = {
  cartomante?: string;
};
export type CanRequestResponse = {
  result?: boolean;
  next?: string;
};
export type FortuneTellerModel = {
  id?: string;
  characteristics?: string[];
  name?: string;
  tags?: string[];
  story?: string;
  sign?: string;
  intro?: string;
  price?: number;
  oldPrice?: number;
  imageUri?: string;
  review?: number;
  reviewCount?: number;
  services?: number;
  consult?: number;
  like?: number;
};
export type ServiceModel = {
  id?: number;
  name?: string;
  imageUri?: string;
  instructions?: string[];
  fast?: boolean;
  discount?: boolean;
  active?: boolean;
  description?: string;
  maxParole?: number;
  responseIn?: number;
  price?: number;
  oldPrice?: number;
};
export type ReviewModel = {
  star?: number;
  name?: string;
  review?: string;
};
export type FortuneTellerDetailModel = {
  serviceModels?: ServiceModel[];
  reviewAvg?: number;
  reviewCount?: number;
  reviewModels?: ReviewModel[];
  name?: string;
  id?: string;
  services?: number;
  review?: number;
  imageUri?: string;
  sign?: string;
  like?: number;
  price?: number;
  consult?: number;
  story?: string;
  intro?: string;
  oldPrice?: number;
  tags?: string[];
  characteristics?: string[];
};
export const {
  useTrackMutation,
  useCreateOrderMutation,
  useSubscribeMailListMutation,
  useUnsubscribeMailListMutation,
  useRequestFreeConsultMutation,
  useSaveReviewForCartomanteMutation,
  useSaveContactRequestMutation,
  useGetReviewsQuery,
  useGetOrderByIdQuery,
  useCanRequestV2Query,
  useCanRequestQuery,
  useGetFortuneTellerQuery,
  useGetFortuneTellerDetailQuery,
} = injectedRtkApi;
