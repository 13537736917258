import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PurchaseGuide = () => {
    return (
        <>
            <SEO name={"purchase_guide"} title="Guida all'acquisto al tuo consulto di cartomanzia"  description={"guida all'acquisto al tuo consulti di cartomanzia"}/>
            <Layout>
                <BreadcrumbOne 
                    title="Purchase Guide"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Guida all'acquisto"
                />
                <div className="edu-purchase-guide-area edu-purchase-guide edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="gallery-column gallery-column-2 mb--50">
                                        <div className="gallery-image">
                                            <img className="radius-small w-100" src="/images/others/purchase-guide-01.jpg" alt="Purchase Guide Thumb 1"/>
                                        </div>
                                        <div className="gallery-image">
                                            <img className="radius-small w-100" src="/images/others/purchase-guide-02.jpg" alt="Purchase Guide Thumb 2"/>
                                        </div>
                                    </div>


                                    Benvenuti nella nostra guida all'acquisto. Seguite questi semplici passaggi per selezionare il vostro cartomante e il servizio desiderato, e per ricevere il vostro
                                    consulto in modo rapido e sicuro.

                                    <h4 className="title">Scegliere il Cartomante</h4>
                                    <ol>
                                        <li>Esplora il Nostro Team di Cartomanti:** Visita la nostra sezione "Cartomanti" per conoscere i nostri esperti. Ogni cartomante ha un profilo dettagliato che
                                            include informazioni sulle loro specialità, esperienze e metodi di lettura.
                                        </li>
                                        <li>Seleziona il Cartomante:** Scegli il cartomante che meglio risponde alle tue esigenze e che senti possa offrirti la guida più appropriata.</li>
                                    </ol>

                                    <h4 className="title">Scegliere il Servizio</h4>
                                    <ol>
                                        <li>Esplora i Servizi Offerti:** Visita la nostra sezione "Servizi" per vedere le diverse tipologie di consulti disponibili
                                        </li>
                                        <li>Leggi le Descrizioni dei Servizi:** Ogni servizio ha una descrizione dettagliata che include le caratteristiche specifiche, la durata del consulto e il
                                            tempo
                                            di risposta previsto
                                        </li>
                                        <li>Seleziona il Servizio:** Scegli il servizio che desideri acquistare cliccando sul pulsante "Acquista Ora".
                                        </li>
                                    </ol>

                                    <h4 className="title">Conferma e Pagamento</h4>

                                    <ol>
                                        <li>Controlla attentamente le informazioni inserite e assicurati che tutti i dettagli siano corretti.</li>
                                        <li>Effettua il Pagamento:** Procedi con il pagamento utilizzando uno dei metodi accettati (carte di credito, carte di debito, PayPal). Tutte le transazioni sono
                                            sicure e criptate
                                        </li>
                                    </ol>

                                    <h4 className="title">Scrivere il Consulto</h4>

                                    <ol>

                                        <li>Scrivi una descrizione chiara e dettagliata della tua domanda o delle aree in cui desideri ricevere guida. Assicurati di includere:
                                            Nomi e Date: Indica il tuo nome e la tua data di nascita, oltre a quelli della persona a cui ti riferisci nel consulto, se applicabile.
                                            Dettagli Specifici: Fornisci tutte le informazioni rilevanti che possano aiutare il l cartomante a comprendere meglio la tua situazione e a offrire una consulenza accurata
                                        </li>
                                        <li>Considera le Caratteristiche del Servizio:** Assicurati che la tua domanda sia in linea con le caratteristiche del servizio acquistato. Ad esempio, se hai
                                            acquistato una lettura delle carte lenormand, formula la tua domanda in modo specifico per questo tipo di lettura
                                        </li>
                                    </ol>

                                    <h4 className="title">Ricezione della Risposta</h4>

                                    <ol>
                                        <li>**Tempo di Risposta:** Dopo aver completato l'acquisto e inviato il consulto, riceverai una risposta entro il tempo indicato nella descrizione del servizio.
                                            Il tempo di risposta può variare a seconda del tipo di servizio acquistato e della disponibilità del cartomante.
                                        </li>
                                        <li>**Ricezione via Email:** La risposta al tuo consulto verrà inviata all'indirizzo email che hai fornito durante la compilazione del modulo. Assicurati che
                                            l'indirizzo email sia corretto e controlla regolarmente la tua casella di posta, inclusa la cartella dello spam.
                                        </li>
                                    </ol>

                                    <h4 className="title">Contatti e Supporto</h4>


                                    <p>Se hai domande o hai bisogno di assistenza durante il processo di acquisto, non esitare a contattarci. Puoi raggiungerci tramite il modulo di contatto sul
                                    nostro sito web, via email o telefonicamente. Siamo qui per aiutarti e garantire che la tua esperienza con noi sia positiva e soddisfacente.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PurchaseGuide;
