import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';

const Footer = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img width={"90%"} height={"90%"} className="logo-light" src="/images/logo/logo.png" alt="logo la mia cartomante" />
                                        </Link>
                                    </div>
                                    <p className="description">La mia cartomante fornisce consulti di cartomanzia su amore e lavoro</p>
                                    {/*<ul className="social-share">*/}
                                    {/*    <li><a href="#"><i className="icon-Fb"></i></a></li>*/}
                                    {/*    <li><a href="#"><i className="icon-linkedin"></i></a></li>*/}
                                    {/*    <li><a href="#"><i className="icon-Pinterest"></i></a></li>*/}
                                    {/*    <li><a href="#"><i className="icon-Twitter"></i></a></li>*/}
                                    {/*</ul>*/}
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Esplora</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/"><i className="icon-Double-arrow"></i>Home</Link></li>
                                            <li><Link to="/cartomanzia-gratis"><i className="icon-Double-arrow"></i>Cartomanzia Gratis</Link></li>
                                            <li><Link to="/cartomanti"><i className="icon-Double-arrow"></i>I Nostri cartomanti</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Link Utili</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/faq"><i className="icon-Double-arrow"></i>FAQ</Link></li>
                                            <li><Link to="/purchase-guide"><i className="icon-Double-arrow"></i>Guida acquisto</Link></li>
                                            {/*<li><Link to="/testimonial"><i className="icon-Double-arrow"></i>Testimonianze</Link></li>*/}
                                            <li><Link to="/privacy-policy"><i className="icon-Double-arrow"></i>Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contatti</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                {/*<li><i className="icon-phone-fill"></i><a href="tel: + 1">+ 1</a></li>*/}
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">info@lamiacartomante.com</a></li>
                                                <li><i className="icon-discuss-line"></i><Link to="/contatti">Contattaci</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2024 <a href="#">La mia cartomante</a> Designed By webtemplateconsult. All Rights
                                        Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default Footer;