import React from 'react';
import SEO from '../../common/SEO';
import Banner from '../../components/banner/Banner';
import HomeAbout from '../../components/home-one/HomeAbout';
import Footer from '../../common/footer/Footer';
import HomeItWork from "../../components/home-one/HomeItWork";
import CartomantiListHome from "../../components/home-one/CartomantiListHome";
import HeaderOne from "../../common/header/HeaderOne";
import AboutCartomanzia from "../../components/home-one/AboutCartomanzia";
import SectionTitle from "../../common/freeconsult/SectionTitle";
import {Link} from "react-router-dom";
import HomeTestimonial from "../../components/home-one/HomeTestimonial";
import {useTracker} from "../../hooks/tracking/tracking.hook";

const Home = () => {

  let tracker = useTracker();

  const faq = [
    {
      id: 1,
      title: 'Cos\'è la cartomanzia?',
      body: '' +
          '<p>La cartomanzia è una pratica divinatoria che utilizza le carte da gioco per ottenere intuizioni e previsioni sul futuro. Questa antica arte, che ha radici profonde nelle tradizioni' +
          'esoteriche e spirituali, consente ai cartomanti di interpretare i simboli e le immagini presenti sulle carte per fornire consigli e risposte a domande su vari aspetti della vita,' +
          ' come amore, lavoro, salute e denaro.' +
          '</p>' +
          '<p>Le carte più comunemente usate nella cartomanzia sono i Tarocchi, le Sibille e le carte Lenormand, ognuna delle quali ha un proprio set di simboli e significati. Durante un consulto' +
          ' di cartomanzia, il cartomante dispone le carte in specifici schemi, noti come "stese", e interpreta le combinazioni e le posizioni delle carte per rivelare informazioni nascoste e guidare il consultante.' +
          '</p>' +
          '<p>La cartomanzia è apprezzata non solo per le sue potenzialità predittive, ma anche per la sua capacità di offrire introspezioni personali e riflessioni profonde. Molti trovano' +
          ' conforto e chiarezza attraverso i consulti di cartomanzia, utilizzandoli come uno strumento di crescita personale e spirituale.' +
          '</p>' +
          'Se desideri esplorare il mondo della cartomanzia e scoprire cosa le carte hanno da dirti, i nostri esperti cartomanti sono a tua disposizione per offrirti consulti personalizzati e professionali. Contattaci per prenotare una lettura e iniziare il tuo viaggio nella conoscenza di te stesso e del tuo futuro.'
    },
    {
      id: 2,
      title: 'La cartomanzia può predire il futuro con precisione?',
      body: '<p>La cartomanzia non è una scienza esatta e non può predire il futuro con certezza assoluta. Tuttavia, può offrire intuizioni e indicazioni preziose che aiutano a comprendere meglio situazioni presenti e future. Molte persone trovano che i consulti di cartomanzia forniscono prospettive utili e li aiutano a prendere decisioni più consapevoli.</p>' +
          '<p>Non è necessario avere una forte convinzione nella cartomanzia per ottenere un consulto utile. Anche i più scettici possono trarre beneficio dalle intuizioni offerte durante una lettura. La cartomanzia può essere vista come uno strumento di riflessione personale e di esplorazione interiore, indipendentemente dal livello di credenza del cliente.</p>'
    },
    {
      id: 3,
      title: 'Come posso chiedere un servizio?',
      body: '<p><b>Scegli il tipo di consulto</b>: Puoi scegliere tra varie tipologie di servizi con prezzi che cambiano a secondo del numero di domande. Assicurati che le domande siano chiare e' +
          ' specifiche per' +
          ' ottenere risposte accurate. In alternativa, puoi ottenere gratuitamente un consulto di cartomanzia compilando il <b><a href="/cartomanzia-gratis">form dei consulti gratis' +
          ' cliccando qui </a></b></p>' +
          '<p>' +
          '<b>Effettua il pagamento</b>:  procedi con il pagamento tramite PayPal. Puoi utilizzare qualsiasi carta di credito, Postepay o un conto PayPal. Basta selezionare l\'opzione desiderata' +
          ' durante il processo di pagamento. Il sistema è semplice, veloce e sicuro.' +
          '</p>' +
          '<b>Invia la tua domanda</b>: Compila il modulo sottostante con le tue domande. Se una domanda è vaga o se includi più domande rispetto all\'opzione scelta, ci riserviamo il diritto di' +
          ' riformulare una domanda precisa basata sulle informazioni fornite. Questa domanda riformulata ti sarà comunicata insieme alla risposta. Attenzione controlla la <b><a' +
          ' href="/faq">sezione' +
          ' domande frequenti</a></b> per capire come richiedere un consulto. ' +
          'Dettagli personali: Per un consulto accurato, includi il tuo nome, la tua data di nascita e, se pertinente, i nomi e le date di nascita delle altre persone coinvolte nelle tue' +
          ' domande.</p>' +
          '<p>' +
          '<b>Ricevi la tua risposta</b>: Una volta ricevuta la richiesta elaboreremo la tua richiesta e riceverai la risposta all\'indirizzo email fornito. Assicurati di fornire un indirizzo email' +
          ' corretto per evitare' +
          ' ritardi nella ricezione del responso.' +
          '</p>'
    },
    {
      id: 4,
      title: 'I consulti sono confidenziali?',
      body: 'Assolutamente SI,tutte le domande sono strettamente confidenziali. Riceverai la risposta solo tramite email indicata in fase di richiesta. Rispettiamo la privacy e la riservatezza di tutti i nostri clienti. Nessuna informazione condivisa verrà divulgata.'
    }
  ]

  return (
      <>
        <SEO name={"home"} title="Cartomanzia Online - Consulto Gratuito e Professionale" description={"Scopri il tuo destino con i nostri consulti di cartomanzia online gratuiti e professionali." +
            " Offriamo consulti di cartomanzia gratuiti e, per approfondire, consulti personalizzati ."}/>

        <HeaderOne/>

        <Banner/>

        <div className="mt--30 eduvibe-home-five-progress  edu-service-area edu-section-gap bg-image">
          <SectionTitle
              classes="text-center "
              slogan="Vuoi esplorare i misteri del destino e dell’amore?"
              title="Richiedi un Consulto Gratuito di cartomanzia"
          />

          <div className="row text-center mt--30 mb--30">
            <div className="col-lg-12">
              <div className="load-more-btn animated fadeInUp">
                <Link className="edu-btn" to="/cartomanzia-gratis">Richiedi il tuo consulto gratuito<i
                    className="icon-arrow-right-line-right" onClick={()=>{
                  tracker.trackWith('free_consult','free_consult_request_home_click')
                }}></i></Link>
              </div>
            </div>
          </div>


        </div>

        <CartomantiListHome/>

        <HomeTestimonial/>

        <AboutCartomanzia faq={faq}/>

        <HomeItWork/>

        <HomeAbout/>


        <Footer/>
      </>
  )
}

export default Home;