import {useCookies} from "react-cookie";
import {getIpInfo} from "../../hooks/tracking/api";
import {IpInfoResponse} from "../../hooks/tracking/types";
import {useEffect, useState} from "react";
import {useCanRequestQuery} from "../../api/allApi";

export const useCheckCanSendConsult =  () => {

  const [cookies] = useCookies(["user"]);

  const user = cookies.user;


  const [ipInfo, setIpInfo] = useState<IpInfoResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);


  const {isLoading,data} = useCanRequestQuery({ip:ipInfo?.ip||'',session:user})


  useEffect(() => {
    setLoading(true);
    getIpInfo()
    .then(info => {
      setIpInfo(info);
    })
    .catch(err => {
      setIpInfo(null);
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);


  return {loading: loading||isLoading, canSend: data?.result||false,user,ip:ipInfo?.ip};
};