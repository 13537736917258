import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useCreateOrderMutation} from "../../api/allApi";
import {useEffect} from "react";
import {useUserTracker} from "../../hooks/useTracker";

const Buy = () => {

  const [searchParams] = useSearchParams();

  const user = useUserTracker();

  const cartomante = searchParams.get('cartomante')!!;

  const [createOrder] = useCreateOrderMutation();

  const navigate = useNavigate();


  useEffect(() => {

    const promise = createOrder({
      orderRequest: {
        cartomante: cartomante,
        serviceId: Number(searchParams.get('serviceId')!!),
        userReference: user
      }
    }).unwrap();
    promise.then(val => {
      console.log(val);
      window.location.href = val.location!! + '?backUrl=https://www.lamiacartomante.com/cartomante/' + cartomante;
    }).catch(()=>{
      navigate("/");
    })
  }, [cartomante, searchParams, user]);

  return <></>;

}

export default Buy