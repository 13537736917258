import React from 'react'
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO name={"privacy"} title="Privacy Policy" description={""} skipIndex={true}/>
            <Layout>
                <BreadcrumbOne 
                    title="Privacy Policy"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Privacy Policy"
                />
                <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="content">
                                <div className="thumbnail mb--50">
                                    <img className="radius-small w-100" src="/images/others/privacy.png" alt="Privacy Policy"/>
                                </div>


                                <h4 className="title">Introduzione</h4>


                                <p>Benvenuti sul nostro sito web di cartomanzia. Utilizzando i nostri servizi, accetti di essere vincolato dai seguenti Termini e Condizioni. Si prega di leggere
                                    attentamente queste condizioni prima di utilizzare i nostri servizi. Se non accetti questi termini, ti preghiamo di non utilizzare il sito e i servizi offerti.</p>

                                <h4 className="title">Servizi Offerti</h4>


                                <p>Forniamo letture di carte, consulenze personalizzate e sessioni di life coaching. I nostri servizi sono intesi come strumenti di
                                    riflessione e guida spirituale e non devono essere interpretati come consulenze mediche, legali o finanziarie.</p>

                                <h4 className="title">Utilizzo del Servizio</h4>

                                <ol>
                                    <li>Utilizzo del servizio:** Per accedere ad alcuni servizi, potrebbe essere necessario fornire informazioni personali. Sei responsabile di
                                        mantenere la riservatezza delle credenziali del tuo account e di tutte le attività che avvengono sotto il tuo account.
                                    </li>
                                    <li>Consultazioni Gratuite:** Offriamo consultazioni gratuite tramite un sorteggio giornaliero per gli iscritti alla nostra mailing list. Ogni utente può iscriversi
                                        con una sola email. L'iscrizione con più email comporterà il blocco di tutte le email per errato utilizzo del servizio.
                                    </li>
                                    <li>Domande Inappropriate:** Ci riserviamo il diritto di non rispondere a domande considerate inappropriate, offensive, illegali o che violano la nostra etica
                                        professionale. In tali casi, possiamo annullare la transazione e cancellare la richiesta dal nostro sistema.
                                    </li>
                                    <li>Confidenzialità:** Tutte le consultazioni sono confidenziali. Non divulgheremo informazioni personali a terzi senza il tuo consenso, salvo dove richiesto dalla
                                        legge.
                                    </li>

                                </ol>

                                <h4 className="title">PagamentiUtilizzo del Servizio</h4>

                                <ol>
                                    <li>Metodi di Pagamento:** Accettiamo pagamenti tramite carte di credito, carte di debito, PayPal, bonifici bancari, Apple Pay e Google Pay. Tutte le transazioni
                                        sono
                                        sicure e criptate
                                    </li>
                                    <li>Addebiti:** Il pagamento verrà addebitato al momento della conferma della prenotazione. Riceverai una conferma via email con i dettagli della transazione.</li>
                                    <li>Rimborsi:** Le richieste di rimborso sono valutate caso per caso. Contattaci entro 24 ore dalla sessione programmata per richiedere un rimborso, spiegando il
                                        motivo della tua richiesta.
                                    </li>
                                </ol>

                                <h4 className="title">Limitazioni di Responsabilità</h4>

                                <ol>
                                    <li>Accuratezza delle Letture:** Le letture di carte sono interpretazioni personali e non garantiamo l'accuratezza delle informazioni fornite. Utilizza i nostri
                                        servizi come strumento di riflessione e non come guida assoluta
                                    </li>
                                    <li>Consulenze Mediche/Legali:** I nostri servizi non sostituiscono consulenze mediche, legali o finanziarie professionali. Consultati con un professionista
                                        qualificato per questioni di salute, legali o finanziarie
                                    </li>
                                </ol>

                                <h4 className="title">Modifiche ai Termini e Condizioni</h4>

                                Ci riserviamo il diritto di modificare questi Termini e Condizioni in qualsiasi momento. Le modifiche saranno efficaci al momento della pubblicazione sul sito. È
                                tua
                                responsabilità controllare periodicamente i Termini e Condizioni per eventuali aggiornamenti.

                                <h4 className="title">Contatti</h4>

                                <p>Per qualsiasi domanda o dubbio riguardante questi Termini e Condizioni, puoi contattarci tramite il modulo di contatto sul nostro sito web, via email o
                                    telefonicamente.</p>

                                <h4 className="title">Gestione dei Cookie:</h4>
                                <p>Puoi gestire le tue preferenze sui cookie tramite le impostazioni del tuo browser. Puoi scegliere di bloccare o eliminare i cookie, ma ciò
                                    potrebbe
                                    influire sulla tua esperienza di navigazione.</p>

                                <h4 className="title">Dati Salvati</h4>
                                <p>I dati personali raccolti tramite il sito, come nome, indirizzo email e richieste inviate sono utilizzati esclusivamente per fornire i servizi
                                    richiesti. Non condivideremo, venderemo o affitteremo i tuoi dati a terzi.</p>

                                <h4 className="title">Conservazione dei Dati</h4>
                                <p>Conserviamo i tuoi dati personali solo per il tempo necessario a fornire i servizi richiesti e per ottemperare agli obblighi legali. Adottiamo
                                    misure di sicurezza appropriate per proteggere i tuoi dati contro accessi non autorizzati, modifiche, divulgazioni o distruzioni.</p>

                                <h4 className="title">Diritti dell'Utente</h4>
                                <p>Hai il diritto di accedere, correggere, cancellare e limitare l'uso dei tuoi dati personali. Puoi esercitare questi diritti contattandoci tramite
                                    il modulo di contatto sul nostro sito web o via email.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PrivacyPolicy;