import React from 'react';
import { Link } from 'react-router-dom';

const Sign = ({ data, classes, bgWhite }:{data:any,classes?:any,bgWhite?:any} ) => {
    return (
        <div className={`edu-blog blog-type-2 radius-small ${classes ? classes : '' } ${bgWhite === 'enable' ? 'bg-color-white' : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/oroscopo/segno/${data.name}`}>
                        <img src={`${process.env.PUBLIC_URL}/images/sign/${data.image}`} alt="Blog Thumb" />
                    </Link>
                </div>
                <div className="content">
                    <h5 className="title">
                        <Link to={process.env.PUBLIC_URL + `/oroscopo/segno/${data.name}`}>{data.title}</Link>
                    </h5>
                    <div className="blog-card-bottom">
                        <div className="read-more-btn">
                            <Link className="btn-transparent" to={process.env.PUBLIC_URL + `/oroscopo/segno/${data.name}`}>Scopri<i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sign;