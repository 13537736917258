import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';
import {useGetFortuneTellerQuery} from "../../api/allApi";
import ReactPlaceholder from "react-placeholder/lib/ReactPlaceholder";

const Banner = () => {

    const {data:cartomanti,isLoading} = useGetFortuneTellerQuery();


    const initialPrice = (cartomanti && cartomanti.length > 0) ? cartomanti[0].price||0: 0;
    const minPrice = cartomanti?.filter(v =>v.price).reduce((min, item) => {
        return item.price||0 < min ? item.price||0 : min;
    }, initialPrice);



    return (
        <div className="slider-area banner-style-1 bg-image d-flex align-items-center">
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 row--40 align-items-center">
                    <div className="order-1 order-xl-1 col-lg-12 col-xl-6">
                        <div className="banner-left-content">
                            <div className="inner">
                                <div className="content">
                                    <h1 className="pre-title">Cartomanzia per il Cuore e l'Anima</h1>
                                    <ReactPlaceholder type='media' rows={6} ready={!isLoading}>
                                        <h3 className="title">{`Scopri il tuo destino con i nostri consulti di cartomanzia online. Il primo consulto è gratis`}</h3>
                                        <p className="description">
                                            Benvenuti su lamiacartomante.com! <br/>
                                            {`Offriamo consulti di cartomanzia via email gratuiti e, per approfondire, consulti personalizzati a partire da ${minPrice} €.`} <br/>
                                            Scopri il tuo futuro in amore e felicità con l'aiuto dei nostri esperti cartomanti.</p>
                                    </ReactPlaceholder>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="order-2 order-xl-2 col-lg-12 col-xl-6 banner-right-content">
                        <div className="row g-5">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="edu-card card-type-6 radius-small" style={{marginBottom: "30px"}}>
                                    <div className="inner">
                                        <div className="thumbnail center-home-1">
                                        {/*<Link to="/cartomanti">*/}
                                            {/*    <img src="images/home/home_carte1.jpeg"*/}
                                            {/*         alt="Course Meta"/>*/}
                                            {/*</Link>*/}

                                        </div>
                                        <div className="content">
                                            <ul className="edu-meta meta-04">
                                                <li><i className="icon-file-list-3-line"></i>5 Cartomanti</li>
                                                <li><i className="icon-time-line"></i>Disponibilità immediata</li>
                                            </ul>
                                            <h6 className="title"><Link to="/cartomanti">Visioni d'Amore: Il Sentiero delle Carte per la
                                                Felicità</Link></h6>
                                            <div className="card-bottom">
                                                <p>Scopri il Futuro del Tuo Cuore con Letture Cartomantiche Personalizzate e Consigli Affettuosi.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="work-shop">*/}
                                {/*    <div className="inner">*/}
                                {/*        <div className="thumbnail">*/}
                                {/*            <img src="images/home/home_carte2.jpeg" alt="Workshop Images" />*/}
                                {/*        </div>*/}
                                {/*        <div className="content">*/}
                                {/*            <h6 className="title">Design Workshop</h6>*/}
                                {/*            <span className="time">Today at 6:00 am</span>*/}
                                {/*            <div>*/}
                                {/*        </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                            <div className="col-md-6 col-sm-6 d-none d-lg-block">
                                <div className="video-thumbnail eduvibe-hero-one-video">
                                    <div className="thumbnail">
                                        <img className="w-100" src="images/home/home1.jpeg" alt="Video Images"/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape shape-1">
                        <img src="/images/shapes/shape-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-2">
                        <img src="/images/shapes/shape-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-4">
                        <img src="/images/shapes/shape-04.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-5">
                        <img src="/images/shapes/shape-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-6">
                        <img src="/images/shapes/shape-05-05.png" alt="Shape Thumb" />
                    </div>
                </div>

                <div className="shape-round">
                    <img src="/images/banner/banner-01/shape-27.png" alt="Shape Images" />
                </div>

            </div>

        </div>

    )
}
export default Banner;