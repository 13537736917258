import {BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'

import axios, {AxiosError, AxiosRequestConfig} from "axios";


const jwtAxios = axios.create({
    baseURL: "/api/", // YOUR_API_URL HERE
    headers: {
        "Content-Type": "application/json",
    },
});

const axiosBaseQuery = (
    ): BaseQueryFn<
        {
            url: string
            method?: AxiosRequestConfig['method']
            body?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
        },
        unknown,
        unknown
        > =>
        async ({ url, method, body, params }) => {
            try {
                const result = await jwtAxios({ url:  url, method, data:body, params })
                return { data: result.data }
            } catch (axiosError) {
                let err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }


const tags = [
] as const;

export const api = createApi({
    baseQuery: axiosBaseQuery(),
    tagTypes: tags,
    endpoints: (build) => ({
    }),
})
