import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactForm from "./ContactForm";


const Contact = () => {
  return (
      <>
        <SEO name={"contact"} title="Contattaci" description={"Contattaci"} skipIndex={true}/>
        <Layout>
          <BreadcrumbOne
              title="Contattaci"
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Contattaci"
          />
          <div className="eduvibe-contact-me-top edu-contact-me-area about-me-1 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
              <div className="row g-5">
                <div className="col-lg-6">
                  <div className="pr--75 pr_lg--0 pr_md--0 pr_sm--0">
                    <div className="thumbnail">
                      <img className="w-100" src="/images/contact/contact.png" alt="Contact Me"/>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="section-title text-start mb--45">
                    <h3 className="title">Invia un messaggio</h3>
                    <p>Per problemi sui servizi acquistati o malfunzionamenti sul portale, inviare una richiesta compilando i campi sotto</p>
                    <p>*Attenzione, non inviare richieste ai nostri cartomanti da questa pagina</p>
                  </div>
                  <ContactForm formStyle="rnt-contact-form rwt-dynamic-form row"/>
                </div>
              </div>

              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src="/images/shapes/shape-03-11.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-2">
                  <img src="/images/shapes/shape-15-06.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-3">
                  <img src="/images/shapes/shape-09-03.png" alt="Shape Thumb"/>
                </div>
                <div className="shape-image shape-image-4">
                  <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb"/>
                </div>
              </div>
            </div>
          </div>

        </Layout>
      </>
  )
}
export default Contact;