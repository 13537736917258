import React, {useRef, useState} from 'react';
import {useSaveContactRequestMutation} from "../../api/allApi";
import ReCAPTCHA from "react-google-recaptcha";


const Result = () => {
  return (
      <p className="success-message">Richiesta inviata, ti rispondiamo nel piu breve tempo possibile.</p>
  )
}

const ContactForm = ({formStyle}: { formStyle?: any }) => {
  const [result, setResult] = useState(false);

  const recaptcha = useRef<ReCAPTCHA>(null);


  const [saveContactRequest] = useSaveContactRequestMutation();

  const [formValue, setFormValue] = useState({fullname: '', email: '', subject: '', message: ''});

  const sendEmail = (e: any) => {
    e.preventDefault();
    const captchaValue = recaptcha.current?.getValue()

    if (!captchaValue) {
      alert('Clicca non sono un robot!')
    } else {
      saveContactRequest({contactRequest:{
          captchaValue: captchaValue,
          ...formValue,

        }}).unwrap().then((result) => {
        setResult(true);
      }).catch(()=>{
        setResult(false);
      })
    }


  };

  const onChange = (name: 'fullname' | 'email' | 'subject' | 'message') => {
    return (e: any)=>{
      formValue[name]= e.target.value;
      setFormValue(formValue);
    }
  }

  return (
      <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
        <div className="col-lg-6">
          <div className="form-group">
            <input disabled={result} onChange={onChange('fullname')} type="text" className="form-control form-control-lg" name="fullname" placeholder="Nome*" required/>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="form-group">
            <input disabled={result} onChange={onChange('email')} type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required/>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group">
            <select disabled={result} onChange={onChange('subject')} required className="form-control form-control-lg" name="subject">
              <option>Altro</option>
              <option>Problemi pagamento</option>
              <option>Non ho ricevuto il consulto</option>
              <option>Rimborso</option>
            </select>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group">
            <textarea disabled={result} onChange={onChange('message')} className="form-control" name="message" placeholder="Il tuo messaggio"></textarea>
          </div>
        </div>

        <div className="col-lg-12">
          <button disabled={result} className="rn-btn edu-btn" type="submit">
            <span>Invia la richiesta</span><i className="icon-arrow-right-line-right"></i>
          </button>
        </div>

        <div className="text-center" style={{marginTop: "10px", display: "flex", justifyContent: "start"}}>
          <ReCAPTCHA ref={recaptcha} sitekey={"6Ldi6u4pAAAAAHsz0_wPBzTPU-VOldj6j2CmatNp"}/>
        </div>


        {result ? <div className="form-group"><Result/></div> : null}


      </form>
  )
};

export default ContactForm;