import React from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const items = [
    {
        title: 'Ovunque',
        info: 'Scopri il tuo destino amoroso e professionale comodamente da casa tua. Ricevi consigli personalizzati via email da esperte cartomanti, pronte a illuminare il tuo cammino.',
        icon: 'icon-Hand---Book'
    },
    {
        title: 'Sicuro',
        info: 'Con i nostri consulti di cartomanzia via email, puoi avere accesso immediato alle risposte che cerchi sulle tue relazioni e il tuo futuro professionale. Pagamenti sicuri e risposte rapide.',
        icon: 'icon-Campus'
    }
];

const HomeAbout = () => {
    return (
        <Element
            name="about-us"
            className="edu-about-area about-style-1 edu-section-gap bg-color-white"
        >
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="image-1" src="/images/home/about_us1.jpeg" alt="About Main Thumb" />
                            <div className="image-2">
                                <img src="/images/home/about_us4.jpeg" alt="About Parallax Thumb" />
                            </div>


                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner">
                            <SectionTitle
                                slogan = "Chi siamo"
                                title = "Vi guidiamo Verso l'Amore e la Realizzazione Personale"
                            />
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={ true }
                            >
                                <p className="description">Siamo un team di cartomanti dedicati a offrire letture precise e consigli amorevoli per ispirare la tua vita quotidiana.</p>
                            </ScrollAnimation>
                            { items && items.length > 0 &&
                                <div className="about-feature-list">
                                    { items.map( ( data , i ) => (
                                        <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            className="our-feature"
                                            animateOnce={ true }
                                            key={i}
                                        >
                                            <div className="our-feature">
                                                <div className="icon">
                                                    <i className={ data.icon }></i>
                                                </div>
                                                <div className="feature-content">
                                                    <h6 className="feature-title">{ data.title }</h6>
                                                    <p className="feature-description">{ data.info }</p>
                                                </div>
                                            </div>
                                        </ScrollAnimation>
                                    ) ) }
                                </div>
                            }
                            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default HomeAbout;