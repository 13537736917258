export const uniqueFilter = <T>(value:T, index:number, self:T[]) => self.indexOf(value) === index;


export const formattedPrice = (price?:number) => {
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(price|| 0);
};

export const firstLetterUpperCase = (string?:string)=> {
    if(string)
        return string.charAt(0).toUpperCase() + string.slice(1);
    return string;
};