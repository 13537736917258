import React from 'react';
import HeaderOne from './header/HeaderOne';
import Footer from './footer/Footer';

const Layout = ( { children }:{children:React.ReactNode} ) => {
    return (
        <>
            <main className="main-wrapper">
                <HeaderOne />
                { children }
                <Footer />
            </main>
        </>
    )
}
export default Layout;