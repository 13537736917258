import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const faqCategories = [
    {
        id: 1,
        slug: 'services',
        label: 'Come funziona'
    },
    {
        id: 2,
        slug: 'payment-method',
        label: 'Metodo di pagamento'
    },
    {
        id: 3,
        slug: 'free-consult',
        label: 'Consulti Gratuiti'
    }
];

const onlineEducation = {
    leftColumn: [
        {
            id: 1,
            title: 'Cos\'è la cartomanzia',
            body: 'La cartomanzia è l\'arte di leggere le carte per ottenere informazioni sul passato, presente e futuro di una persona. Utilizza mazzi di carte speciali, come i tarocchi, per interpretare i simboli e fornire risposte e consigli'
        },
        {
            id: 2,
            title: 'La cartomanzia è affidabile?',
            body: 'La cartomanzia è vista come una pratica spirituale e interpretativa. L\'accuratezza può variare e dipende dall\'abilità del cartomante e dall\'atteggiamento del cliente. È importante considerare le letture come uno strumento di riflessione e guida'
        },
        {
            id: 3,
            title: 'Quali servizi di cartomanzia offrite?',
            body: 'Offriamo letture di tarocchi, consulenze personalizzate e sessioni di life coaching. Ogni servizio è progettato per rispondere a diverse esigenze e domande.'
        },
        {
            id: 4,
            title: 'Come posso comprare un servizio?',
            body: 'Puoi acquistare un servizio sul nostro sito scegliendo il cartomante e selezionando uno dei servizi che offre'
        },
        {
            id: 5,
            title: 'I consulti sono confidenziali?',
            body: 'Assolutamente SI,tutte le domande  sono strettamente confidenziali. Riceverai la risposta solo tramite email indicata in fase di richiesta. Rispettiamo la privacy e la' +
                ' riservatezza di tutti i nostri clienti. Nessuna informazione condivisa verrà divulgata. '
        },
        {
            id: 5,
            title: 'Come garantite la confidenzialità delle consultazioni?',
            body: 'Invieremo le risposte solo sulla tua email privata, inoltre cancelleremo dopo 24 dalla risposta tutti i dati della consultazione.  Utilizziamo tecnologie di sicurezza avanzate' +
                ' per proteggere i tuoi dati e tutte le comunicazioni avvengono attraverso canali sicuri. Inoltre, il nostro team è formato per mantenere la massima discrezione'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Posso fare domande su qualsiasi argomento?',
            body: 'NO, puoi fare domande su alcuni argomenti, esempio amore, carriera e crescita personale. Tuttavia, ricorda che i cartomanti non sono medici né avvocati, quindi per cui non forniamo consulti su questioni legali o di salute.'
        },
        {
            id: 2,
            title: 'Quali tipi di domande sono considerate inappropriate?',
            body: 'Le domande considerate inappropriate includono, ma non sono limitate a: Domande su attività illegali, Domande offensive, discriminatorie o che incitano all\'odio. Richieste di' +
                ' predizioni specifiche su morte, malattie gravi o scommesse.'
        },
        {
            id: 5,
            title: 'Cosa succede se una domanda è ritenuta inappropriata?',
            body: 'Se una domanda viene ritenuta inappropriata, il cartomante si riserva il diritto di non rispondere. In casi gravi, ci riserviamo il diritto di annullare la transazione e cancellare la richiesta dal nostro sistema.Se la tua richiesta viene annullata a causa di domande inappropriate, riceverai una notifica via email. L\'importo pagato per la lettura verrà rimborsato secondo le nostre politiche di rimborso. La tua richiesta verrà cancellata dal nostro sistema per garantire la privacy e la sicurezza'
        },
        {
            id: 3,
            title: 'Cosa succede se la mia richiesta viene annullata?',
            body: 'Se la tua richiesta viene annullata a causa di domande inappropriate, riceverai una notifica via email. L\'importo pagato per la lettura verrà rimborsato secondo le nostre' +
                ' politiche di rimborso. La tua richiesta verrà cancellata dal nostro sistema per garantire la privacy e la sicurezza..'
        },
        {
            id: 4,
            title: 'Quanto costa un servizio?',
            body: 'I prezzi variano a seconda del tipo di servizio, in base al numero di domande e alle tempistiche di risposta. Scegli il cartomante per avere il prezzo dettagliato.'
        },
        {
            id: 5,
            title: 'Come funzionano le promozioni?',
            body: 'Registrandoti alla nostra mail list, puoi ricevere offerte con vari scolti sui servizi offerti dai nostri cartomanti, se non vuoi registrarti visita il nostro sito per' +
                ' controllare se ci sono promozioni attive'
        },
    ]
}

const paymentMethod = {
    leftColumn: [
        {
            id: 1,
            title: 'Quali metodi di pagamento accettate?',
            body: 'Accettiamo i seguenti metodi di pagamento, Carte di credito (Visa, MasterCard, American Express), Paypal'
        },
        {
            id: 2,
            title: 'È sicuro effettuare un pagamento sul vostro sito?',
            body: 'Si, per tutti i pagamenti usiamo Paypal, nessun dato di pagamento viene salvato sui nostri sistemi'
        },
        {
            id: 3,
            title: 'Posso richiedere un rimborso?',
            body: 'Si, se non hai usufruito del servizio che hai comprato puoi chiedere un rimborso, invia una richiesta nella sezione contattaci'
        },

    ],
    rightColumn: [
        {
            id: 1,
            title: 'Cosa devo fare se il mio pagamento viene rifiutato?',
            body: 'Se il tuo pagamento viene rifiutato, verifica di aver inserito correttamente tutte le informazioni richieste e che il tuo metodo di pagamento abbia fondi sufficienti. Se il problema persiste, contatta il tuo istituto finanziario o il nostro servizio clienti per ulteriori assistenza.'
        },
        {
            id: 2,
            title: 'Riceverò una ricevuta per il mio pagamento?',
            body: 'Sì, una ricevuta elettronica ti verrà inviata via email una volta completata la transazione. Se hai bisogno di una copia cartacea, puoi richiederla al nostro servizio clienti.'
        },
        {
            id: 3,
            title: 'Cosa devo fare se ho problemi con il pagamento?',
            body: 'Se riscontri problemi con il pagamento, contatta il nostro servizio clienti tramite il modulo di contatto sul nostro sito oppure via email. Saremo lieti di aiutarti a risolvere' +
                ' qualsiasi problema.'
        }
    ]
}

const freeConsult = {
    leftColumn: [
        {
            id: 1,
            title: 'Come posso ottenere una consultazione gratuita di cartomanzia?',
            body: 'Puoi ottenere una consultazione gratuita iscrivendoti alla nostra mailing list. Ogni giorno, selezioniamo casualmente un fortunato iscritto che riceverà un consulto gratuito da' +
                ' una nostra cartomante.'
        },
        {
            id: 2,
            title: 'Come verrò avvisato se vinco una consultazione gratuita?',
            body: 'Se vinci una consultazione gratuita, riceverai un\'email di notifica con le istruzioni per chiedere il consulto. Assicurati che l\'indirizzo email fornito sia corretto e' +
                ' controlla regolarmente la tua' +
                ' casella di' +
                ' posta.'
        },
        {
            id: 3,
            title: 'Posso partecipare al sorteggio più di una volta?',
            body: 'Sì, una volta iscritto alla mailing list, sarai automaticamente incluso nel sorteggio ogni giorno. Non è necessario iscriversi più volte.'
        },
        {
            id: 4,
            title: 'Posso annullare l\'iscrizione alla mailing list?',
            body: 'Sì, puoi annullare l\'iscrizione in qualsiasi momento cliccando sul link di cancellazione presente in fondo a ogni email che inviamo. Ricorda che, annullando l\'iscrizione, non sarai più incluso nei sorteggi giornalieri.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Cosa succede se mi iscrivo alla mailing list con più email?',
            body: 'Se ti iscrivi alla mailing list con più email, bloccheremo tutte le tue email per errato utilizzo del servizio. È importante rispettare le regole per' +
                ' garantire una' +
                ' possibilità equa a tutti i partecipanti.'
        },
        {
            id: 2,
            title: 'Posso cedere la mia consultazione gratuita a qualcun altro?',
            body: 'No, le consultazioni gratuite sono personali e non trasferibili. Solo il vincitore può usufruire del premio.'
        },
        {
            id: 3,
            title: 'Quali sono i vantaggi di rimanere iscritto alla mailing list oltre al sorteggio?',
            body: 'Oltre alla possibilità di vincere una consultazione gratuita, gli iscritti alla nostra mailing list riceveranno aggiornamenti esclusivi e offerte speciali.'
        }
    ]
}


const CustomToggle = ({ children, eventKey }: { children: any; eventKey:any; }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey } className="edu-accordion-button">{ children }</button>
}

const Faq = ( { wrapperClass }:{ wrapperClass?: any; } ) => {
    const [selectedItem, setSelectedItem] = useState( 'services' );
    const [activeId1, setActiveId1] = useState<string|null>( '0' );
    const [activeId2, setActiveId2] = useState<string|null>('0');
    const [activeId3, setActiveId3] = useState<string|null>('0');
    const [activeId4, setActiveId4] = useState<string|null>('0');
    const [activeId5, setActiveId5] = useState<string|null>('0');
    const [activeId6, setActiveId6] = useState<string|null>('0');

    function toggleActive1( id:string ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id:string ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id:string ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id:string ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5( id:string ) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6( id:string ) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <>
            <SEO name={"faq"} title="Domande Frequenti sulla Cartomanzia - Risposte Dettagliate" description={"Hai delle domande sulla cartomanzia? Leggi le nostre risposte dettagliate alle domande" +
                " frequenti" +
                " sui consulti di cartomanzia, i metodi utilizzati e altro ancora."} />
            <Layout>
                <BreadcrumbOne
                    title="Domande Frequenti"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="FAQ"
                />
                <div className={ `edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${ wrapperClass ? wrapperClass : '' } ` }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title">Domande Frequenti</span>
                                    <h3 className="title">In questa sezione otterai risposte alle domande frequenti</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="edu-tab-nav nav nav-tabs mt--40">
                                    {
                                        faqCategories.map( ( button ) => (
                                            <li className="nav-item" key={ button.id }>
                                                <button
                                                    className={ button.slug === selectedItem ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label={button.label}
                                                    onClick={ () => setSelectedItem( button.slug ) }
                                                >
                                                    { button.label }
                                                </button>
                                            </li>
                                        ) )
                                    }
                                </ul>
                            </div>
                        </div>
                        { selectedItem === 'services' &&
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                onlineEducation.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                onlineEducation.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive2(i.toString())} bsPrefix={`edu-accordion-item ${activeId2 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'payment-method' &&
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                paymentMethod.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive3(i.toString())} bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                paymentMethod.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive4(i.toString())} bsPrefix={`edu-accordion-item ${activeId4 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }

                        { selectedItem === 'free-consult' &&
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                freeConsult.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive5(i.toString())} bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                freeConsult.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive6(i.toString())} bsPrefix={`edu-accordion-item ${activeId6 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Faq;