import React from 'react';
import { Link } from 'react-router-dom';
import {FortuneTellerModel} from "../../api/allApi";
import {firstLetterUpperCase} from "../../app/utils";
import {renderStars} from "../../pages/cartomante/Utils";


const CartomanteTile = ({ data, classes }:{data:FortuneTellerModel,classes?:any}) => {

 const introFull =data.intro ? data.intro.substring(0, 142) + "...":'';

    return (
        <div className={`edu-card card-type-3 radius-small ${ classes ? classes : '' }`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/cartomante/${data.name}`}>
                        <img  style={{borderRadius:"50%",margin:0, width:"30%"}} src={data.imageUri} alt={data.name} />
                    </Link>
                    <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div>
                    <div className="top-position status-group left-bottom">
                        <div className="eduvibe-status status-03" dangerouslySetInnerHTML={{__html:data.characteristics?.slice(0, 1)? data.characteristics?.slice(0, 1)[0]:''}}>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/cartomante/${data.name}`}>{firstLetterUpperCase(data.name)}</Link>
                    </h6>

                    <ul className="edu-meta meta-01">
                        {/*<li><i className="icon-group-line"></i>{data.consult} Consulti Effettuati</li>*/}
                        <li><i className="icon-file-list-4-line"></i>{data.services} Servizi offerti</li>
                    </ul>

                    <div className="card-bottom">
                        <div className="price-list price-style-02">
                            Prezzi da <div className="price current-price">{data.price} €</div>
                            {
                                (data.oldPrice !==data.price) && <div className="price old-price">{data.oldPrice} €</div>
                            }
                        </div>
                        <div className="edu-rating rating-default">
                            {renderStars(data.review||0)}
                            <span className="rating-count">({data.reviewCount})</span>
                        </div>


                    </div>

                </div>
            </div>

            <div className="card-hover-action">

                <div className="hover-content">

                    <div className="content-top">
                        <div className="top-status-bar">
                            <p className="description" dangerouslySetInnerHTML={{__html: introFull}}></p>
                        </div>
                        <div className="top-wishlist-bar">
                            <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                        </div>
                    </div>

                    <div className="price-list price-style-02">
                        <div className="price current-price">Prezzi da: {data.price} €</div>
                        {
                            (data.oldPrice !== data.price) && <div className="price old-price">{data.oldPrice} €</div>
                        }
                    </div>

                    <div className="hover-bottom-content">
                        {/*<div className="author-meta">*/}
                        {/*    <div className="author-thumb">*/}

                        {/*        <Link to={process.env.PUBLIC_URL + `/cartomante/${data.name}`}>*/}
                        {/*            /!*<img style={{borderRadius: "50%", margin: 0, width: "40%"}}*!/*/}
                        {/*            /!*     src={data.imageUri} alt="Cartomante"/>*!/*/}
                        {/*            <span className="author-title">{firstLetterUpperCase(data.name)}</span>*/}
                        {/*        </Link>*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<ul className="edu-meta meta-02">*/}
                        {/*    <li><i className="icon-group-line"></i>{data.consult} Consulti Effettuati</li>*/}
                        {/*</ul>*/}
                    </div>
                    <div className="read-more-btn">

                        <Link className="edu-btn btn-medium btn-white" to={process.env.PUBLIC_URL + `/cartomante/${data.name}`}>
                            Acquisto adesso<i className="icon-arrow-right-line-right"></i>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CartomanteTile;