import React from 'react';
import { useParams } from 'react-router-dom';

import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Tarotdata from './Tarot.json';

const TarotDetail = () => {
    const { name } = useParams();

    const data = Tarotdata.filter(v => v.name===name)[0];


    return (
        <>
            <SEO name={"tarot_detail"} title={"Tarocchi Arcani maggiori "+data.name} description={"Tarocchi Arcani maggiori "+data.name+ " "+data.descrizione_long} />
            <Layout>
                <BreadcrumbOne 
                    title={ data.title }
                    rootUrl="/tarocchi/arcani-maggiori"
                    parentUrl="Tarocchi Arcani Maggiori"
                    currentUrl={""+data.title}
                />
                <div className="edu-blog-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-1 style-variation3">

                                    <div className="content-blog-top">


                                        <h4 className="title">{ data.title }</h4>

                                        <div className="thumbnail block-alignwide" style={{textAlign:'center'}}>
                                            <img className="radius-small w-25 mb--30 mr--30" src={`${process.env.PUBLIC_URL}/images/tarot/${data.image}`} alt="Blog Thumb" />
                                        </div>
                                    </div>

                                    <div className="blog-main-content">

                                        <blockquote className="blockquote-style-1">“ {data.descrizione}”
                                            <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images"/>
                                        </blockquote>

                                        <p>{data.descrizione_long}</p>


                                        <h5 className="title">Carta Diritta</h5>
                                        <p>{data.carta_dritta}</p>

                                        <h5 className="title">Carta Rovesciata</h5>
                                        <p>{data.carta_rovesciata}</p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default TarotDetail;