import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import HowItWorksHome from "./HowItWorks";

const HomeItWork = () => {
    return (
        <div className="eduvibe-home-five-progress  edu-service-area edu-section-gap bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classes = "text-center"
                            slogan = ""
                            title = "Come il nostro sito funziona ?"
                        />
                    </div>
                </div>

                <HowItWorksHome />

            </div>
        </div>
    )
}

export default HomeItWork;